import { NotificationItemStatus, NotificationListItem } from "@coherence-design-system/controls";

import { CoherenceTheme } from "@coherence-design-system/styles";
import dayjs from "dayjs";
import { NotificationType } from "./types";

export function getNotificationItem(type: NotificationType, body: string, header = "", icon = "", iconColor = "") {
    const _header = header
        ? header
        : type === "error"
        ? "An error has occured"
        : type === "success"
        ? "Operation was successful"
        : "Attention";

    const _iconColor = iconColor
        ? iconColor
        : type === "error"
        ? CoherenceTheme.palette.red
        : type === "success"
        ? CoherenceTheme.palette.green
        : CoherenceTheme.palette.blue;

    const _icon = icon ? icon : type === "error" ? "Error" : type === "success" ? "CheckMark" : "Info";

    const notification: NotificationListItem = {
        itemKey: crypto.randomUUID(),
        timeDelivered: dayjs(),
        status: NotificationItemStatus.UNREAD,
        subjectHeader: _header,
        subjectIcon: _icon,
        subjectIconColor: _iconColor,
        summaryBodyText: body
    };
    return notification;
}
