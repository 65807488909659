import * as React from "react";
import { IContextualMenuProps, IIconProps, Stack, IStackStyles } from "@fluentui/react";
import { CommandBarButton } from "@fluentui/react/lib/Button";

export type DownloadSchemaButtonProps = {
    // These are set based on the toggles shown above the examples (not needed in real code)
    disabled?: boolean;
    checked?: boolean;
    multipleOptions?: boolean;
    onClick: (type: "csv" | "json" | undefined) => void;
};

function getMenuProps(onClick: (type: "csv" | "json") => void): IContextualMenuProps {
    return {
        items: [
            {
                key: "csv",
                text: "As CSV",
                onClick: () => onClick("csv")
            },
            {
                key: "json",
                text: "As JSON",
                onClick: () => onClick("json")
            }
        ]
    };
}

const stackStyles: Partial<IStackStyles> = { root: { height: 44 } };
const downloadIcon: IIconProps = { iconName: "DownloadDocument" };
export const DownloadSchemaButton: React.FunctionComponent<DownloadSchemaButtonProps> = (props) => {
    const { disabled, checked, onClick, multipleOptions } = props;

    return (
        <Stack horizontal horizontalAlign="end" styles={stackStyles}>
            <CommandBarButton
                text="Download"
                iconProps={downloadIcon}
                menuProps={multipleOptions ? getMenuProps(onClick) : undefined}
                disabled={disabled}
                checked={checked}
                onClick={() => onClick("csv")}
            />
        </Stack>
    );
};
