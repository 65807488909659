import {
    IDetailsColumnRenderTooltipProps,
    IDetailsHeaderProps,
    IRenderFunction,
    ScrollablePane,
    ScrollbarVisibility,
    Sticky,
    StickyPositionType,
    TooltipHost
} from "@fluentui/react";

export const ScrollablePaneWrapper = ({ children, height }) => (
    <div style={{ position: "relative", height }}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>{children}</ScrollablePane>
    </div>
);

export const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
        return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (tooltipHostProps) => (
        <TooltipHost {...tooltipHostProps} />
    );

    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
            {defaultRender!({
                ...props,
                onRenderColumnHeaderTooltip
            })}
        </Sticky>
    );
};
