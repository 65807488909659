import { FontSizes, mergeStyleSets, Theme } from "@fluentui/react";

const buttonWidth = 48;
export const getHeaderButtonStyles = (theme: Theme) =>
    mergeStyleSets({
        rightItemButton: {
            width: buttonWidth + "px",
            height: buttonWidth + "px",
            backgroundColor: "transparent",
            color: "white",
            ".ms-Button-icon": {
                color: theme.palette.white + " !important",
                fontSize: FontSizes.size16
            },
            selectors: {
                ".ms-Button-icon": {
                    color: theme.palette.white + " !important",
                    fontSize: FontSizes.size16
                },
                ".ms-Button": {
                    color: "white" + " !important",
                    fontSize: FontSizes.size16
                },
                ":hover": {
                    background: "transparent",
                    selectors: {
                        ".ms-Button-flexContainer": {
                            backgroundColor: theme.palette.themeDarkAlt
                        },
                        ".notification-badge": {
                            color: theme.palette.themeDarkAlt,
                            backgroundColor: theme.palette.white
                        }
                    }
                },
                ":active": {
                    background: "transparent",
                    selectors: {
                        ".ms-Button-flexContainer": {
                            backgroundColor: theme.palette.themeDarker
                        },
                        ".notification-badge": {
                            color: theme.palette.themeDarker,
                            backgroundColor: theme.palette.white
                        }
                    }
                },
                "&.is-checked": {
                    background: "transparent",
                    selectors: {
                        ".ms-Button-flexContainer": {
                            backgroundColor: theme.palette.themeDarker
                        },
                        ".notification-badge": {
                            color: theme.palette.themeDarker,
                            backgroundColor: theme.palette.white
                        }
                    }
                },
                ":focus::after": {
                    border: "1px solid " + theme.semanticColors.bodyText + " !important"
                }
            }
        },
        rightItemButtonIcon: {
            color: "white" + " !important",
            ".ms-Button-icon": {
                color: theme.palette.white + " !important",
                fontSize: FontSizes.size16
            },
            selectors: {
                ".ms-Button-icon": {
                    color: "white" + " !important",
                    fontSize: FontSizes.size16
                }
            },
            backgroundColor: "transparent !important",
            fontSize: FontSizes.size16
        }
    });
