import { Stack, Separator } from "@fluentui/react";
import { FormTextField } from "../../../common/components/FormTextField";
import { DatasetContentProps } from "../DatasetCardGrid/Types";

export function SdwDatasetContent({ currentDataSet }: DatasetContentProps) {
    return (
        <Stack>
            <Separator>Connection Details</Separator>

            <FormTextField label="Connection String" txt={currentDataSet.sdwConnectionString} />
            <FormTextField label="Type" txt={currentDataSet.sdwType} />
            <FormTextField label="Name" txt={currentDataSet.sdwName} />
            <Separator>Additional Information</Separator>
            <FormTextField
                label="Retention Period"
                txt={`${currentDataSet.sdwRetentionInDays} ${
                    currentDataSet.sdwRetentionInDays && currentDataSet.sdwRetentionInDays > 1 ? "Days" : "Day"
                }`}
            />
            {currentDataSet.sdwSnapshotType && (
                <FormTextField label="Snapshot Type" txt={currentDataSet.sdwSnapshotType} />
            )}
        </Stack>
    );
}
