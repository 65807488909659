import { NotificationListItem } from "@coherence-design-system/controls";
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state";

interface NotificationState {
    notifications: NotificationListItem[];
    isPreviewEnabled: boolean;
}
export const sliceName = "notifications";

const initialState: NotificationState = {
    notifications: [],
    isPreviewEnabled: false
};

export const notificationsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        setIsPreviewEnabled: (state, action: PayloadAction<boolean>) => {
            state.isPreviewEnabled = action.payload;
        },
        addNotification: (state, action: PayloadAction<NotificationListItem>) => {
            state.notifications.push(action.payload as Draft<NotificationListItem>);
        },
        removeNotification: (state, action: PayloadAction<string>) => {
            state.notifications.splice(
                state.notifications.findIndex((x) => x.itemKey === action.payload),
                1
            );
        },
        clearNotifications: (state) => {
            state.notifications = [];
        }
    }
});

export const { addNotification, removeNotification, clearNotifications, setIsPreviewEnabled } =
    notificationsSlice.actions;

export const selectNotifications = (state: RootState) => state.notifications;

export default notificationsSlice.reducer;
