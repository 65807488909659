import { IconButton, IButtonStyles } from "@fluentui/react/lib/Button";
import { IOverflowSetItemProps, OverflowSet } from "@fluentui/react/lib/OverflowSet";
import { IContextualMenuListProps, mergeStyleSets, Stack } from "@fluentui/react";
import { tagClassNames } from "./utils";

const noOp = () => undefined;

const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
    return <div className={tagClassNames.tag}>{item.name}</div>;
};

const onRenderMenuList = (props?: IContextualMenuListProps) => {
    if (props) {
        //remove dupes
        const items = Array.from(new Set(props.items.map((x) => x.text)));
        return (
            <Stack horizontal wrap className={tagClassNames.taglist}>
                {items.map((x) => (
                    <Stack.Item key={x} className={tagClassNames.tag}>
                        {x}
                    </Stack.Item>
                ))}
            </Stack>
        );
    } else {
        return null;
    }
};
const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
    const buttonStyles: Partial<IButtonStyles> = {
        root: {
            minWidth: 0,
            padding: "0 4px",
            height: "auto"
        }
    };
    return (
        <IconButton
            title="More options"
            styles={buttonStyles}
            menuIconProps={{ iconName: "More" }}
            menuProps={{ items: overflowItems!, onRenderMenuList: onRenderMenuList }}
        />
    );
};

type TagSetProps = {
    names: string[];
};

const TagSet = ({ names }: TagSetProps) => {
    function getItems(start: number, end: number) {
        return names.slice(start, end).map((x) => ({
            key: x,
            name: x,
            text: x,
            onClick: noOp
        }));
    }
    function getTag() {
        if (names.length > 3) {
            const items = getItems(0, 3);
            const overflowItems = getItems(3, names.length);

            return { items, overflowItems };
        } else {
            const items = getItems(0, names.length);
            const overflowItems = [];

            return { items, overflowItems };
        }
    }

    return (
        <OverflowSet
            aria-label="Dataset Tags"
            {...getTag()}
            onRenderOverflowButton={onRenderOverflowButton}
            onRenderItem={onRenderItem}
        />
    );
};

export { TagSet };
