import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authenticationParameters, authProvider } from "../../common/auth";
import { DataSetDetail } from "./DataSetDetail.types";
import { DataSetSummary } from "./DataSetSummary.types";

export const allDataSetsApi = createApi({
    reducerPath: "allDataSets",
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
        prepareHeaders: async (headers, {}) => {
            await authProvider.acquireTokenSilent(authenticationParameters).then((token) => {
                if (token) {
                    if (token.accessToken) {
                        headers.set("authorization", `Bearer ${token.accessToken}`);
                    }
                }
            });
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getAllDataSets: builder.query<DataSetSummary[], void>({
            query: () => `api/datasets`,
            keepUnusedDataFor: 60
        }),
        getDataSet: builder.query<DataSetDetail, number>({
            query: (id: number) => `api/datasets/${id}`,
            keepUnusedDataFor: 60
        })
    })
});

export const { useGetAllDataSetsQuery, useGetDataSetQuery, usePrefetch } = allDataSetsApi;
