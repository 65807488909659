import {
    DetailsListLayoutMode,
    FontIcon,
    IColumn,
    Link,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    SelectionMode,
    Shimmer,
    Stack,
    Text
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { dataCatalogClient } from "../../common/clients";
import { ShimmeredList } from "../../common/components/ShimmerList";
import { useAppDispatch } from "../../common/state";
import { setError } from "../../common/state/errorReducer";
import { parseCSV } from "../../common/utils";
import { DataSetDetailProps } from "../DataSets/DataSetDetail.types";
import { downloadContent } from "../DataSets/SchemaViews/utils";
import { IGenericView } from "./RenderTableView.types";

// create temp columns
const temp: IColumn[] = [];
Array(10)
    .fill(0)
    .forEach((x) =>
        temp.push({
            key: x,
            name: x,
            minWidth: 200,
            onRenderHeader: () => <Shimmer>loading</Shimmer>
        })
    );

export function SampleData({ dataset, type }: DataSetDetailProps & { type: "adls" | "kusto" | "sdw" }) {
    const [sampleData, setSampleData] = useState<string>();
    const [sanitized, setSanitized] = useState<boolean>(false);
    const [viewItems, setViewItems] = useState<IGenericView[]>();
    const [viewColumns, setColumnItems] = useState<IColumn[]>(temp);
    const [error, _setError] = useState<boolean>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        getSampleData();
    }, [dataset.id, type]);

    async function getSampleData() {
        try {
            _setError(false);
            var paramid;
            switch (type) {
                case "kusto":
                    paramid = [dataset.kustoId];
                    break;
                case "sdw":
                    paramid = [dataset.sdwId];
                    break;
                default:
                    paramid = [dataset.adlsId];
                    break;
            }
            const { data, headers } = await dataCatalogClient.get<string>(`datasets/getdatasetdata/${type}/${paramid}`);
            const isSanitized = headers["x-sanitized"];

            setSanitized(isSanitized === "True");

            setSampleData(data);
        } catch (error) {
            _setError(true);
            dispatch(
                setError({
                    message: `An error occured getting sample data for ${dataset.name}`,
                    errorType: "SampleData"
                })
            );
        }
    }

    useEffect(() => {
        if (sampleData) {
            const [headings, ...rows] = parseCSV(sampleData);
            const _viewItems: IGenericView[] = [];
            const _viewColumns: IColumn[] = [];

            // Sample Data View
            if (headings != null) {
                headings.forEach((hVal) => {
                    _viewColumns.push({
                        key: hVal,
                        name: hVal,
                        fieldName: hVal,
                        minWidth: 210,
                        isRowHeader: true,
                        isResizable: true,
                        data: "string",
                        isPadded: true
                    });
                });
            }

            if (rows != null && rows.length != 0) {
                let rEntry: IGenericView[] = [];

                rows.forEach((r) => {
                    rEntry = [];
                    r.forEach((cVal, index) => {
                        rEntry.push({ [headings[index]]: cVal == '""' ? "" : cVal });
                    });
                    const obj = Object.assign({}, ...rEntry);
                    _viewItems.push(obj);
                });
                // constructing as single object
            }

            setColumnItems(_viewColumns);
            setViewItems(_viewItems);
        }
    }, [sampleData]);

    return (
        <>
            <Stack className={contentStyles.container}>
                {viewItems && viewItems.length != 0 && (
                    <>
                        <Stack.Item>
                            <MessageBar messageBarType={MessageBarType.severeWarning}>
                                <b>Warning: Regarding Sharing SIP Data Broadly</b> <br />
                                Please be aware that any data you share on this application will be visible to anyone
                                who can see your screen. This may include data that you have agreed to keep confidential
                                per the SIP User Agreement. Please do not share your screen or the underlying data on
                                this application. Thank you for using our application and respecting SIPs terms of
                                service.
                            </MessageBar>
                        </Stack.Item>
                        <Stack.Item
                            style={{
                                paddingRight: 30,
                                marginTop: 10,
                                marginRight: 30,
                                marginBottom: 10,
                                textAlign: "right"
                            }}
                        >
                            <FontIcon aria-label="Download" iconName="Download" />{" "}
                            <Link
                                style={{ fontSize: 15 }}
                                onClick={() => {
                                    downloadContent(sampleData, dataset.name);
                                }}
                            >
                                Download
                            </Link>
                        </Stack.Item>
                    </>
                )}
                {sanitized && (
                    <Stack.Item align="center">
                        <Text variant="mediumPlus" style={{ fontWeight: "bold" }}>
                            Sanitized Data
                        </Text>
                    </Stack.Item>
                )}
                <Stack.Item style={{ paddingLeft: 10 }}>
                    <ShimmeredList
                        height="53vh"
                        items={viewItems || []}
                        compact={true}
                        columns={viewColumns}
                        selectionMode={SelectionMode.none}
                        setKey="none"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        isPlaceholderData={true}
                        enableShimmer={!viewItems && !error}
                    />
                </Stack.Item>
            </Stack>
        </>
    );
}

const contentStyles = mergeStyleSets({
    container: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
        selector: {
            "& .ms-List-page": {
                height: "auto !important"
            }
        }
    },
    downloadLinkStyle: {
        width: "50px",
        display: "inline",
        borderRadius: "7px",
        padding: "0px 10px 0px 10px",
        fontWeight: "bold",
        marginRight: "8px"
    },
    errorMessageStyle: {
        marginLeft: 20,
        marginRight: 20
    }
});
