import { ConstrainMode, IShimmeredDetailsListProps, ShimmeredDetailsList, withViewport } from "@fluentui/react";
import { ScrollablePaneWrapper, onRenderDetailsHeader } from "./ScrollablePaneWrapper";

export type ShimmeredListProps = {
    height: string;
} & IShimmeredDetailsListProps;
export function ShimmeredList(props: ShimmeredListProps) {
    const { height, items } = props;
    return (
        <ScrollablePaneWrapper height={height}>
            <ShimmeredDetailsList
                shimmerLines={15}
                constrainMode={ConstrainMode.unconstrained}
                onRenderDetailsHeader={onRenderDetailsHeader}
                enableShimmer={!items || items.length === 0}
                {...props}
            />
        </ScrollablePaneWrapper>
    );
}
