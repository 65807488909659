import { useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";

const useIsAdmin = () => {
    const [isAdmin, setAdmin] = React.useState(false);
    const { instance } = useMsal();

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account) {
            if (account.idTokenClaims?.roles?.includes("Admin")) {
                setAdmin(true);
            }
        }
    }, [instance]);

    return isAdmin;
};

export default useIsAdmin;
