import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authenticationParameters, authProvider } from "../auth";
import { IDataQualityMetrics } from "../../features/RenderTableView";


// SDW Data Quality API

export const getSDWDataQualityApi = createApi({
    reducerPath: "sdwDataQualityAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
        prepareHeaders: async (headers, { }) => {
            await authProvider.acquireTokenSilent(authenticationParameters).then((token) => {
                if (token) {
                    if (token.accessToken) {
                        headers.set("authorization", `Bearer ${token.accessToken}`);
                    }
                }
            });
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getSDWDataQuality: builder.query<IDataQualityMetrics[], string>({
            query: (apiURL: string) => `${apiURL}`,
            keepUnusedDataFor: 60
        })
    })
});

// Kusto Data Quality API

export const getKustoDataQualityApi = createApi({
    reducerPath: "kustoDataQualityAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
        prepareHeaders: async (headers, { }) => {
            await authProvider.acquireTokenSilent(authenticationParameters).then((token) => {
                if (token) {
                    if (token.accessToken) {
                        headers.set("authorization", `Bearer ${token.accessToken}`);
                    }
                }
            });
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getKustoDataQuality: builder.query<IDataQualityMetrics[], string>({
            query: (apiURL: string) => `${apiURL}`,
            keepUnusedDataFor: 60
        })
    })
});

export const { useGetSDWDataQualityQuery, usePrefetch } = getSDWDataQualityApi;
export const { useGetKustoDataQualityQuery} = getKustoDataQualityApi;
