import axios from "axios";
import { authenticationParameters, authProvider } from "../auth";

const dataCatalogClient = axios.create({ baseURL: "api/" });

dataCatalogClient.interceptors.request.use(
    async (config) => {
        if (config && config.headers) {
            const token = await authProvider.acquireTokenSilent(authenticationParameters);

            if (token) {
                config.headers["Authorization"] = `Bearer ${token.accessToken}`;
            } else {
                console.error("Could not get access token");
            }
        }

        return config;
    },
    (error) => {
        throw error;
    }
);

export { dataCatalogClient };
