import {
    Announced, IBasePickerSuggestionsProps, IPersonaProps, NormalPeoplePicker, PeoplePickerItemSuggestion
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import React, { useState } from "react";
import { searchAAD } from "../../api/OBOAccessRequestIntakeApi";
import { listContainsTagList } from "../utils";
import { AADState } from "./Types";

export type GraphLookupProps = {
    aadState: AADState[];
    setState: React.Dispatch<React.SetStateAction<any>>;
    editDisabled: boolean;
    title: string;
};
export function GraphLookupList(props: GraphLookupProps) {
    const { aadState: state, setState, editDisabled, title } = props;

    const [msg, setMsg] = useState<string>("");
    const pickerId = useId(`${title}-picker`);

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: `Suggested ${title}`,
        noResultsFoundText: `No ${title} found`,
        suggestionsContainerAriaLabel: `Suggested ${title}`,
        suggestionsAvailableAlertText: "Suggestions Available",
        loadingText: "Suggestions loading"
    };

    const filterSuggestedTags = async (filterText: string, tagList?: IPersonaProps[]): Promise<IPersonaProps[]> => {
        if (filterText) {
            const resp = await searchAAD(filterText, "All");
            if (!resp.error) {
                const tags: IPersonaProps[] = resp.data.map((x) => ({
                    secondaryText: x.email || "No Email",
                    text: x.name,
                    itemID: x.objectId,
                    itemType: x.type
                }));
                const filteredTags = tags.filter((tag) => !listContainsTagList(tag, tagList));
                setMsg(`Loaded ${filteredTags.length} contacts`);
                return filteredTags;
            }
        }
        return [];
    };
    const onRenderSuggestionItem = (personaProps: IPersonaProps, suggestionsProps: IBasePickerSuggestionsProps) => {
        return (
            <PeoplePickerItemSuggestion
                personaProps={personaProps}
                suggestionsProps={suggestionsProps}
                styles={{ personaWrapper: { width: "100%" } }}
            />
        );
    };

    return (
        <>
            <Announced id={`${props.title}_annoucement`} aria-live="assertive" message={msg} />
            <NormalPeoplePicker
                styles={{ root: { background: "#FFFFFF", width: "500px" } }}
                aria-label={title}
                enableSelectedSuggestionAlert={true}
                removeButtonAriaLabel={`Remove ${title}`}
                onChange={(item) => {
                    if (item && item.length > 0) {
                        const mapped: AADState[] = item.map((n) => ({
                            AADEmail: n.secondaryText || "",
                            AADName: n.text || "",
                            AADObjectId: n.itemID || "",
                            AADType: n.itemType || ""
                        }));
                        const deDuped = mapped.filter(
                            (value, index, self) => index === self.findIndex((t) => t.AADObjectId === value.AADObjectId)
                        );

                        setState(deDuped);
                    } else {
                        setState([]);
                    }
                }}
                selectedItems={
                    state && state.length > 0
                        ? state
                              .filter((x) => x.AADName !== null && x.AADObjectId !== null)
                              .map((aad) => ({
                                  text: aad.AADName,
                                  secondaryText: aad.AADEmail || "No Email",
                                  itemID: aad.AADObjectId,
                                  showSecondaryText: true,
                                  itemType: aad.AADType
                              }))
                        : []
                }
                resolveDelay={700}
                onRenderSuggestionsItem={onRenderSuggestionItem}
                onResolveSuggestions={(filter, selectedItems) => filterSuggestedTags(filter, selectedItems)}
                disabled={editDisabled}
                pickerSuggestionsProps={pickerSuggestionsProps}
                inputProps={{
                    id: pickerId,
                    "aria-label": title
                }}
            />
        </>
    );
}
