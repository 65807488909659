import { Stack, MessageBar, MessageBarType, StackItem, TextField, PrimaryButton } from "@fluentui/react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../common/state";
import { selectError } from "../../../common/state/errorReducer";
import { AccountInfo } from "@azure/msal-browser";
import { IEventTelemetry } from "@microsoft/applicationinsights-web";
import { TelemetryService } from "../../../common/telemetry";
import { useAccount } from "@azure/msal-react";

export const Feedback = ({ id, name, onClose }: { id?: number; name?: string; onClose: () => void }) => {
    const { currentError } = useAppSelector(selectError);
    const params = useParams<{ id: string; name: string }>();
    const _name = name ?? (params.name || "");
    const account = useAccount() as Required<AccountInfo>;
    const [description, setDescription] = useState<string>("");

    function _onClickFeedbackButton(): void {
        const feedbackEvent: IEventTelemetry = {
            name: "Customer Feedback: "
        };
        TelemetryService.trackEvent(feedbackEvent, {
            url: window.location.href.toLowerCase(),
            user_Id: account.username,
            user_DisplayName: account.name,
            datasetName: _name,
            Description: description
        });

        onClose();
    }

    function _captureDescription(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText?: string): void {
        const target = ev.target;
        if (target) {
            if (newText !== undefined) {
                setDescription(newText);
            } else setDescription("");
        }
    }

    return (
        <Stack>
            <Stack style={{ width: "600px", height: "150px", marginRight: "10px", paddingLeft: 20, paddingRight: 20 }}>
                {currentError && <MessageBar messageBarType={MessageBarType.error}>{currentError.message}</MessageBar>}
                <StackItem>
                    <TextField
                        label="Limit to 500 Chars:"
                        styles={{ root: { width: 500, paddingBottom: 20 } }}
                        onChange={_captureDescription}
                        required
                        value={description}
                        multiline
                        maxLength={500}
                    ></TextField>
                    <PrimaryButton
                        text="Submit"
                        type="Button"
                        onClick={() => {
                            if (description) {
                                _onClickFeedbackButton();
                            }
                        }}
                    ></PrimaryButton>
                </StackItem>
            </Stack>
        </Stack>
    );
};
