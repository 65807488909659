import {
    Stack,
    Persona,
    PersonaSize,
    IPersonaProps,
    IconButton,
    Label,
    Shimmer,
    IPersonaSharedProps,
    ShimmerElementType,
    OverflowSet,
    IOverflowSetItemProps,
    IButtonStyles,
    IContextualMenuListProps
} from "@fluentui/react";
import { ReactElement, useEffect, useState } from "react";
import { getShimmerStyles } from "../../features/DataSets/DatasetCardGrid/utils";
import { useGetDatasetOwnersQuery } from "../../features/DataSets/datasetOwnerSlice";
import { Contact } from "../../features/DataSets/DataSetDetail.types";
import { useIsSmallScreen } from "../hooks/useIsSmallScreen";

const DataSetOwnersDisplay = ({ accessPackageId }: { accessPackageId: string }) => {
    const { data, isLoading, isSuccess, error } = useGetDatasetOwnersQuery(accessPackageId);
    const [dsOwners, setDSOwners] = useState<IOverflowSetItemProps[]>([]);

    const shimmerWithElementFirstRow = [
        { type: ShimmerElementType.circle },
        { type: ShimmerElementType.gap, width: "2%" },
        { type: ShimmerElementType.line }
    ];
    const smallScreen = useIsSmallScreen(1550);
    useEffect(() => {
        if (isSuccess) {
            let OwnerList: IOverflowSetItemProps[] = [];
            data.forEach((d: Contact) => {
                OwnerList.push({ key: d.name, text: d.name, secondaryText: d.email });
            });
            setDSOwners(OwnerList);
        } else if (error) {
            setDSOwners([]);
        }
    }, [data, error]);

    const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
        const buttonStyles: Partial<IButtonStyles> = {
            root: {
                minWidth: 0,
                padding: "0 4px",
                alignSelf: "stretch",
                height: "auto"
            }
        };
        return (
            <IconButton
                title="More options"
                styles={buttonStyles}
                menuIconProps={{ iconName: "More" }}
                menuProps={{ items: overflowItems!, onRenderMenuList }}
            />
        );
    };
    const onRenderMenuList = (props?: IContextualMenuListProps) => {
        if (props && props.items) {
            return (
                <Stack styles={{ root: { padding: "15px" } }} tokens={{ childrenGap: 10 }}>
                    {props.items.map((x) => onRenderOverflowItem(x))}
                </Stack>
            );
        } else {
            return null;
        }
    };

    const onRenderOverflowItem = (item: any): JSX.Element => {
        console.log(item);

        return (
            <Persona {...item} size={PersonaSize.size40} onRenderSecondaryText={() => _onRenderSecondaryText(item)} />
        );
    };

    const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
        return (
            <div style={{ padding: "5px" }}>
                <Persona
                    {...item}
                    size={PersonaSize.size40}
                    onRenderSecondaryText={() => _onRenderSecondaryText(item)}
                />
            </div>
        );
    };
    function getOwners() {
        const ownerItems = dsOwners.map(
            (o, index): IOverflowSetItemProps => ({
                key: "Persona" + index,
                text: o.text,
                secondaryText: o.secondaryText
            })
        );

        return (
            <OverflowSet
                vertical={smallScreen}
                items={ownerItems.slice(0, 3)}
                overflowItems={ownerItems.filter((o, index) => index > 2)}
                onRenderItem={onRenderItem}
                onRenderOverflowButton={onRenderOverflowButton}
            />
        );
    }
    function _onRenderSecondaryText(props: IPersonaProps) {
        return props && props.secondaryText ? (
            <Stack horizontal>
                <Stack.Item>
                    <Stack horizontal>
                        <IconButton
                            title={`Send email to ${props.secondaryText}`}
                            aria-label={`Send email to  ${props.secondaryText}`}
                            iconProps={{ iconName: "mail" }}
                            role="link"
                            href={"mailto:" + props.secondaryText}
                        ></IconButton>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <IconButton
                        title="Copy email address to clipboard"
                        aria-label="Copy email address to clipboard"
                        onClick={() => {
                            navigator.clipboard.writeText(props.secondaryText ? props.secondaryText : "");
                        }}
                        styles={{ icon: { fontSize: 14 } }}
                        iconProps={{ iconName: "copy" }}
                    />{" "}
                </Stack.Item>
            </Stack>
        ) : (
            <></>
        );
    }
    return (
        <Stack>
            <Stack.Item>
                <Label style={{ marginRight: 10 }}>{"Dataset Owner(s):"}</Label>
            </Stack.Item>

            {isLoading ? (
                <Shimmer
                    shimmerElements={shimmerWithElementFirstRow}
                    styles={getShimmerStyles}
                    ariaLabel={"Dataset Owner Details"}
                />
            ) : dsOwners && dsOwners.length != 0 ? (
                getOwners()
            ) : (
                <span style={{ display: "block", paddingTop: 5 }}>Not Available</span>
            )}
        </Stack>
    );
};

export { DataSetOwnersDisplay };
