import { SelectionMode } from "@fluentui/react";
import { useEffect, useState } from "react";
import { ShimmeredList } from "../../../common/components/ShimmerList";
import { useGetSDWDataQualityQuery, useGetKustoDataQualityQuery } from "../../../common/state/dataQualityReducer";
import { IDataQualityMetrics } from "../../RenderTableView";
import { DataSetDetailProps } from "../DataSetDetail.types";

import { getDataQualityColumns } from "../SchemaViews/utils";

function DataQuality({ dataset, type }: DataSetDetailProps & { type: string }) {
    const [qualityMetrics, setQualityMetrics] = useState<IDataQualityMetrics[]>([]);

    let apiURL = `api/datasets/getdataquality/${type}/${type === "sdw" ? dataset.sdwId : dataset.kustoId}`
    const { data: sdwData, isLoading: sdwDQIsLoading } = useGetSDWDataQualityQuery(apiURL, { pollingInterval: 600000, skip: type != "sdw" });
    const { data: kustoData, isLoading: kustoDQIsLoading } = useGetKustoDataQualityQuery(apiURL, { pollingInterval: 600000, skip: type != "kusto" });

    // SDW Data Quality metrics
    useEffect(() => {
        if (!sdwDQIsLoading && sdwData) {
            setQualityMetrics(sdwData);
        }
    }, [sdwData, type]);

    // Kusto Data Quality metrics
    useEffect(() => {
        if (!kustoDQIsLoading && kustoData) {
            setQualityMetrics(kustoData);
        }
    }, [kustoData, type]);

    return (
        <>
            <ShimmeredList
                height="60vh"
                selectionMode={SelectionMode.none}
                columns={getDataQualityColumns()}
                items={qualityMetrics ? qualityMetrics.map((x) => ({ columnName: x.columnName, completenessPercent: x.completenessPercent, uniquenessPercent: x.uniquenessPercent, maxLength: x.maxLength })) : []}
                enableShimmer={sdwDQIsLoading}
            />
        </>
    );
}

export { DataQuality };
