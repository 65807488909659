import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import UnAuthenticatedPage from "../../features/UnAuthenticated/UnAuthenticatedPage";

export const RbacGuard = (props: { roles: string[]; Element: JSX.Element }) => {
    const { instance } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(false);

    const onLoad = async () => {
        const accounts = instance.getAllAccounts();

        if (accounts.length > 0) {
            instance.setActiveAccount(accounts[0]);
        }

        const currentAccount = instance.getActiveAccount();

        if (currentAccount && currentAccount.idTokenClaims && currentAccount.idTokenClaims["roles"]) {
            //@ts-ignore ...ts can't deal with records very well
            let intersection = props.roles.filter((role) => currentAccount.idTokenClaims["roles"].includes(role));

            if (intersection.length > 0) {
                setIsAuthorized(true);
            }
        }
    };

    useEffect(() => {
        onLoad();
    }, [instance]);

    return <>{isAuthorized ? props.Element : <UnAuthenticatedPage />}</>;
};
