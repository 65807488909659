import {
    IStyleFunctionOrObject,
    IDetailsColumnStyleProps,
    IDetailsColumnStyles,
    IColumn,
    mergeStyles,
    TooltipHost,
    Icon
} from "@fluentui/react";

export type AdlsSchema = {
    fields: AdlsSchemaField[];
    type: string | AdlsSchema;
    elementType?: string | AdlsSchema;
    keyType?: string;
    valueType?: string;
    valueContainsNull?: boolean;
};

export type AdlsSchemaField = {
    name: string;
    type: string | AdlsSchema;
};
export function getColumns(styles?: IStyleFunctionOrObject<IDetailsColumnStyleProps, IDetailsColumnStyles>) {
    const columns: IColumn[] = [
        { key: "column1", name: "Name", fieldName: "name", minWidth: 100, maxWidth: 200, isResizable: true },
        {
            key: "column2",
            name: "Type",
            fieldName: "value",
            styles,
            minWidth: 100,
            maxWidth: 200,
            isResizable: true
        }
    ];

    return columns;
}

export function getTreeViewStyles() {
    return mergeStyles({
        "fluent-tree-item.selected::after": {
            background: "unset"
        },
        "fluent-tree-item": {
            cursor: "default"
        },
        "fluent-tree-item::part(positioning-region)": {
            background: "none",
            height: "20px"
        },

        "fluent-tree-item::part(content-region)": {
            fontSize: "12px",
            background: "none",
            color: "#605E5C",
            display: "unset",
            lineHeight: "unset"
        }
    });
}

export function getTreeItem(field: AdlsSchemaField, top = false) {
    if (typeof field.type === "string") {
        return (
            <fluent-tree-item key={field.name}>
                {!top ? `${field.name} : ` : ""}
                {field.type}
            </fluent-tree-item>
        );
    } else if (field.type.keyType) {
        return (
            <fluent-tree-item key={field.name}>
                {!top ? `${field.name} : ` : ""}
                {field.type.type} [{field.type.keyType}, {field.type.valueType} (nullable ={" "}
                {field.type.valueContainsNull?.toString()})]
            </fluent-tree-item>
        );
    } else if (field.type.elementType) {
        if (typeof field.type.elementType === "string") {
            return (
                <fluent-tree-item key={field.name}>
                    {!top ? `${field.name} : ` : ""}
                    {field.type.type} ({field.type.elementType})
                </fluent-tree-item>
            );
        } else {
            return (
                <fluent-tree-item key={field.name}>
                    {!top ? `${field.name} : ` : ""}
                    {field.type.type} (object)
                    {field.type.elementType.fields.map((x) => getTreeItem(x))}
                </fluent-tree-item>
            );
        }
    } else {
        return (
            <fluent-tree-item key={field.name}>
                {!top ? `${field.name} : ` : ""}
                {field.type.type} {field.type.fields.map((x) => getTreeItem(x))}
            </fluent-tree-item>
        );
    }
}

// Generic method to Download sample data or schema in csv format
export function downloadContent(data: any, filename: string, fileFormat: string = ".csv") {
    const link = document.createElement("a");
    const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename + fileFormat);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function getDataQualityColumns(styles?: IStyleFunctionOrObject<IDetailsColumnStyleProps, IDetailsColumnStyles>) {
    const columns: IColumn[] = [
        {
            key: "column1",
            name: "Field Name",
            fieldName: "columnName",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true
        },
        {
            key: "column2",
            name: "% Completeness",
            fieldName: "completenessPercent",
            styles,
            minWidth: 100,
            maxWidth: 200,
            isResizable: true
        },
        {
            key: "column3",
            name: "% Uniqueness",
            fieldName: "uniquenessPercent",
            styles,
            minWidth: 100,
            maxWidth: 200,
            isResizable: true
        },
        {
            key: "column4",
            name: "MaxLength",
            fieldName: "maxLength",
            styles,
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRenderHeader: (_item) => (
                <TooltipHost content="No. of chars/digits in the string,date or number">
                    {"Max.Length "}
                    <Icon iconName="Info" />
                </TooltipHost>
            )
        }
    ];

    return columns;
}
