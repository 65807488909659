import {
    Checkbox,
    FontSizes,
    FontWeights,
    IconButton,
    Label,
    Link,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    TextField
} from "@fluentui/react";
import { IBasePickerSuggestionsProps, IInputProps, ITag, TagPicker } from "@fluentui/react/lib/Pickers";
import { Stack } from "@fluentui/react/lib/Stack";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GraphLookupList } from "../../common/components/GraphLookupList/GraphLookupList";
import { AADState } from "../../common/components/GraphLookupList/Types";
import { saveOBORequest, searchSPN } from "../../common/api/OBOAccessRequestIntakeApi";
import { SipMonModal } from "../SipMonModal";
import { AccessRequest } from "./DatasetCardGrid/Types";
import StandardForm from "../../common/components/StandardForm";
import { confirmationContent } from "../../common/utils";
import axios, { Axios, AxiosError } from "axios";

type ClientSideAccessRequest = Pick<
    AccessRequest,
    "id" | "accessPackageReferenceId" | "justification" | "objectId" | "owners"
>;

const EmptySPNRequest: ClientSideAccessRequest = {
    id: 0,
    accessPackageReferenceId: 0,
    justification: "",
    objectId: "",
    owners: ""
};
interface IFormState extends ClientSideAccessRequest {
    message: string;
    errors: { [index: string]: string };
    error: boolean;
}

/* Component's Styles */
const subTextStyle = {
    root: {
        size: 12,
        color: "gray",
        fontWeight: FontWeights.regular,
        padding: 0
    }
};
const textfieldControlStyles = {
    root: {
        maxWidth: "500px !important",
        paddingTop: 10
    }
};
const tagFilterControlStyles = {
    root: {
        maxWidth: "500px !important",
        paddingTop: 10,
        "[class*= 'ms-TagItem']": {
            maxWidth: "450px !important"
        }
    }
};
const row = { display: "flex", paddingLeft: "0px !important", marginLeft: "20px", width: "1100px", marginBottom: 10 };
const column = {
    /* Create two equal columns that sits next to each other */
    flex: "50%"
};

function OBOAccessRequestIntake() {
    const params = useParams<{ aPkgRefId: string; aPkgName: string }>();
    const [FormState, setFormState] = useState<IFormState>({
        ...EmptySPNRequest,
        message: "",
        errors: {},
        error: false
    });
    const [isAgreed, setIsAgreed] = useState(false);

    function _onChange(value: any, stateKey: string) {
        const updatedFormState = {
            ...FormState,
            [stateKey]: value
        };
        setFormState(updatedFormState);
    }

    /* Comms aliases */

    const [commsAliases, setCommsAliases] = useState<AADState[]>([]);
    const [errorMsg, setErrormsg] = useState<string>("");
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState<boolean>(false);

    /* Service Priniciple - Picker */
    const [selectedSpn, setSelectedSpn] = useState<ITag[]>([]);

    const inputProps: IInputProps = {
        "aria-label": "Service principal name Picker",
        placeholder: selectedSpn.length == 0 ? "Start typing spn name or enter object or app id for suggestions" : ""
    };
    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: "Suggested Service Principal",
        noResultsFoundText: "No SPN found"
    };

    const getTextFromItem = (item: ITag): string => {
        return item.name;
    };

    const listContainsDocument = (tag: ITag, tagList: ITag[]) => {
        if (!tagList || !tagList.length || tagList.length === 0) {
            return false;
        }
        return tagList.filter((compareTag) => compareTag.key === tag.key).length > 0;
    };

    // OnResolve Method - spn field
    const onFilterChanged = useCallback(async (filterText: string, tagList: ITag[] | undefined): Promise<ITag[]> => {
        setErrormsg("");

        if (!filterText) return [];
        const { data } = await searchSPN(filterText);
        if (data.errorMessage != null) {
            setErrormsg(data.errorMessage);
        } else if (data != null && data.spnList != null) {
            const activeSPNList = data.spnList.map((spn) => ({ key: spn, name: spn }));
            const filteredTags = filterText
                ? activeSPNList.filter((tag) => !listContainsDocument(tag, tagList ? tagList : []))
                : [];
            return filteredTags;
        }
        return [];
    }, []);

    function isFormValid() {
        if (!isAgreed) return true;
        else if (FormState.justification.trim().length == 0) return true;
        else if (!selectedSpn || selectedSpn.length == 0) return true;
        else if (commsAliases.length == 0) return true;

        return false;
    }

    const handleSave = async () => {
        setErrormsg("");
        setShowLoading(true);
        if (params.aPkgRefId !== undefined && params.aPkgName !== undefined) {
            const dto: ClientSideAccessRequest = {
                id: 0,
                accessPackageReferenceId: Number(params.aPkgRefId),
                justification: FormState.justification,
                objectId: selectedSpn[0].name.split("(")[1].split(")")[0],
                owners: commsAliases.map((u) => u.AADEmail.split("@")[0]).join(",")
            };

            try {
                await saveOBORequest(dto);
                setShowSuccess(true);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response?.data && error.response.data.hasOwnProperty("message")) {
                        setErrormsg((error.response.data as { message: string }).message);
                    } else {
                        setErrormsg("An error occurred while saving the request. Please try again later.");
                    }
                } else {
                    setErrormsg("An error occurred while saving the request. Please try again later.");
                }
            } finally {
                setShowLoading(false);
            }
        }
    };
    const navigate = useNavigate();

    return (
        <>
            <br />
            <StandardForm widthPercent={70} mediumWidthPercent={90} smallWidthPercent={90}>
                <div style={{ margin: "20px 40px" }}>
                    {errorMsg && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            onDismiss={() => {
                                setErrormsg("");
                            }}
                        >
                            {errorMsg}
                        </MessageBar>
                    )}
                    {(showSuccess || showLoading) && (
                        <SipMonModal
                            headerText={"SIP - Access Request"}
                            content={confirmationContent(() => navigate("/"))}
                            isLoading={showLoading}
                            loadingLabel={"Please wait, saving..."}
                            onDismiss={() => {
                                navigate("/");
                            }}
                        />
                    )}
                    <span style={{ float: "right" }}>
                        <IconButton
                            title="Close"
                            onClick={() => window.history.back()}
                            iconProps={{ iconName: "Cancel" }}
                            allowDisabledFocus
                        />
                    </span>
                    <br />
                    <div style={row}>
                        <Label style={{ fontSize: FontSizes.size18 }}>
                            Security Intelligence Platform - Access Requests
                        </Label>
                    </div>
                    <div style={row}>
                        <div style={column}>
                            <Stack
                                style={{ marginRight: "10px", marginTop: 15 }}
                                tokens={{ childrenGap: 10 }}
                                horizontal
                            >
                                <Stack.Item grow={1}>
                                    <Label style={{ padding: 0 }}>
                                        AAD Object Id<span style={{ color: "red" }}> * </span>
                                    </Label>
                                    <Label styles={subTextStyle}>You can find this in the Azure Portal in AAD</Label>
                                    <TagPicker
                                        removeButtonAriaLabel="Remove"
                                        selectionAriaLabel="Selected SPN"
                                        onResolveSuggestions={onFilterChanged}
                                        getTextFromItem={getTextFromItem}
                                        pickerSuggestionsProps={pickerSuggestionsProps}
                                        inputProps={inputProps}
                                        selectedItems={selectedSpn}
                                        onChange={(items) => {
                                            items && setSelectedSpn(items);
                                        }}
                                        itemLimit={1}
                                        resolveDelay={700}
                                        styles={tagFilterControlStyles}
                                    />
                                </Stack.Item>
                            </Stack>
                        </div>
                        <div style={column}>
                            <Stack
                                style={{ marginRight: "10px", marginTop: 15 }}
                                tokens={{ childrenGap: 10 }}
                                horizontal
                            >
                                <Stack.Item grow={1}>
                                    <Label style={{ padding: 0 }}>
                                        Access Package Name <span style={{ color: "red" }}> * </span>
                                    </Label>
                                    <Label styles={subTextStyle}>
                                        Name the access package for which the request is being made
                                    </Label>
                                    <TextField
                                        id="component"
                                        ariaLabel="Access package name"
                                        styles={textfieldControlStyles}
                                        required
                                        value={params != null ? params.aPkgName : ""}
                                        disabled={true}
                                    />
                                </Stack.Item>
                            </Stack>
                        </div>
                    </div>
                    <div style={row}>
                        <div style={column}>
                            <Stack
                                style={{ marginRight: "10px", marginTop: 15 }}
                                tokens={{ childrenGap: 10 }}
                                horizontal
                            >
                                <Stack.Item grow={1}>
                                    <Label style={{ padding: 0 }}>
                                        Comms Aliases<span style={{ color: "red" }}> * </span>
                                    </Label>
                                    <Label styles={subTextStyle}>
                                        Comma separated list of aliases (without suffix) to be used for communications
                                    </Label>
                                    <div style={{ paddingTop: 10 }}>
                                        <GraphLookupList
                                            aadState={commsAliases}
                                            setState={setCommsAliases}
                                            title="Comms Aliases"
                                            editDisabled={false}
                                        />
                                    </div>
                                </Stack.Item>
                            </Stack>
                        </div>
                        <div style={column}>
                            <Stack
                                style={{ marginRight: "10px", marginTop: 15 }}
                                tokens={{ childrenGap: 10 }}
                                horizontal
                            >
                                <Stack.Item grow={1}>
                                    <Label style={{ padding: 0 }}>
                                        Justification <span style={{ color: "red" }}> * </span>
                                    </Label>
                                    <Label styles={subTextStyle}>Justification for SPN access request</Label>
                                    <TextField
                                        id="txtJustification"
                                        ariaLabel="Justifications"
                                        styles={textfieldControlStyles}
                                        placeholder="Max length: 500 characters"
                                        required
                                        height={10}
                                        multiline={true}
                                        onChange={(_e, newValue) => {
                                            if (newValue !== undefined) _onChange(newValue, "justification");
                                        }}
                                        value={FormState.justification}
                                        className="name"
                                        maxLength={500}
                                    />
                                </Stack.Item>
                            </Stack>
                        </div>
                    </div>
                    <div style={row}>
                        <div style={column}>
                            <Stack style={{ marginRight: "10px" }} tokens={{ childrenGap: 10 }} horizontal>
                                <Stack.Item grow={1}>
                                    <Checkbox
                                        id={"chkAgreement"}
                                        styles={{ root: { float: "left" } }}
                                        checked={isAgreed}
                                        onChange={(_e, _checked) => {
                                            setIsAgreed(!isAgreed);
                                        }}
                                    ></Checkbox>
                                    <Label style={{ padding: 0 }}>
                                        Read Confidentiality Agreement? <span style={{ color: "red" }}> * </span>
                                    </Label>
                                    <Label styles={subTextStyle} style={{ clear: "both", paddingTop: 5 }}>
                                        You must view and agree the terms of the Confidentiality Agreement linked in the
                                        description. By selecting yes, stating that you have read and agree to the terms
                                        of the Confidentiality Agreement.
                                        <Link
                                            href="https://polite-bush-0adbeba1e.2.azurestaticapps.net/"
                                            target="_blank"
                                        >
                                            {" "}
                                            SIP Confidentiality Agreement
                                        </Link>
                                    </Label>
                                </Stack.Item>
                            </Stack>
                        </div>
                    </div>
                    <div style={row}>
                        <div style={column}>
                            <Stack
                                style={{ marginRight: "10px", marginTop: 15 }}
                                tokens={{ childrenGap: 10 }}
                                horizontal
                            >
                                <Stack.Item>
                                    <PrimaryButton
                                        text="Submit"
                                        type="button"
                                        disabled={isFormValid()}
                                        onClick={() => {
                                            handleSave();
                                        }}
                                    ></PrimaryButton>
                                </Stack.Item>
                                <Stack.Item>
                                    <PrimaryButton
                                        text="Cancel"
                                        type="button"
                                        onClick={() => window.history.back()}
                                    ></PrimaryButton>
                                </Stack.Item>
                            </Stack>
                        </div>
                    </div>
                </div>
            </StandardForm>
        </>
    );
}

export { OBOAccessRequestIntake };
