import { useAccount } from "@azure/msal-react";
import { Text, Link, Stack } from "@fluentui/react";
import { useNavigate } from "react-router-dom";

export function NoDatasetsAccess() {
    const navigate = useNavigate();
    const account = useAccount();
    return (
        <Stack style={{ margin: "10px" }}>
            <Text variant="mediumPlus">
                The account you are logged in with, <strong>{account?.username}</strong>, doesn't have access to any
                datasets, go to{" "}
                <Link onClick={() => navigate("/allDatasets")} underline>
                    All Datasets
                </Link>{" "}
                to request access
            </Text>
        </Stack>
    );
}
