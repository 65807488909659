import { Stack } from "@fluentui/react";
import { Depths } from "@fluentui/theme";
import React from "react";

interface WrapperProps {
    children: React.ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({ children }) => {
    return (
        <Stack
            verticalAlign="center"
            horizontalAlign="center"
            verticalFill
            styles={{
                root: {
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#F4F4F4"
                }
            }}
        >
            <Stack
                verticalAlign="center"
                tokens={{ childrenGap: 10 }}
                horizontalAlign="center"
                styles={{
                    root: {
                        width: "60%",
                        minWidth: "300px",
                        height: "40%",
                        backgroundColor: "white",
                        borderRadius: "8px",
                        boxShadow: Depths.depth8,
                        padding: "22px",
                        "@media (max-width: 768px)": {
                            height: "40%"
                        }
                    }
                }}
            >
                {children}
            </Stack>
        </Stack>
    );
};

export default Wrapper;
