import { configureStore } from "@reduxjs/toolkit";
import { getSchemaApi } from "../../features/DataSets/SchemaViews/slice";
import { allDataSetsApi } from "../../features/DataSets/slice";
import { rtkErrorLogger } from "./middleware";
import { pageViewsAPI } from "./pageviewReducer";
import rootReducer from "./rootReducer";
import { getDataQualityApi } from "../../features/DataSets/Content/AdlsDataQuality";
import { datasetOwnersApi } from "../../features/DataSets/datasetOwnerSlice";
import { getSDWDataQualityApi, getKustoDataQualityApi } from "./dataQualityReducer";
import { adlsIngestionTrendApi } from "./adlsIngestionTrendReducer";
import { collectionsApi } from "../../features/DataSetCollections/slice";

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false })
            .concat(rtkErrorLogger)
            .concat(pageViewsAPI.middleware)
            .concat(allDataSetsApi.middleware)
            .concat(getSchemaApi.middleware)
            .concat(getDataQualityApi.middleware)
            .concat(getSDWDataQualityApi.middleware)
            .concat(getKustoDataQualityApi.middleware)
            .concat(datasetOwnersApi.middleware)
            .concat(adlsIngestionTrendApi.middleware)
            .concat(collectionsApi.middleware)
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type KnownError = {
    status: string;
    title: string;
    detail: string;
};
