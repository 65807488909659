import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authenticationParameters, authProvider } from "../../common/auth";
import { Contact } from "./DataSetDetail.types";

export const datasetOwnersApi = createApi({
    reducerPath: "datasetOwners",
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
        prepareHeaders: async (headers, { }) => {
            await authProvider.acquireTokenSilent(authenticationParameters).then((token) => {
                if (token) {
                    if (token.accessToken) {
                        headers.set("authorization", `Bearer ${token.accessToken}`);
                    }
                }
            });
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getDatasetOwners: builder.query<Contact[], string>({
            query: (accessPkgId: string) => `api/datasets/getDSOwners/${accessPkgId}`,
            keepUnusedDataFor: 60
        })
    })
});

export const { useGetDatasetOwnersQuery, usePrefetch } = datasetOwnersApi;
