import { IPersonaProps } from "@fluentui/react";

export const listContainsTagList = (tag: IPersonaProps, tagList?: IPersonaProps[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
        return false;
    }
    return tagList.some((compareTag) => compareTag.secondaryText === tag.secondaryText && compareTag.text === tag.text);
};

export const nameof = <T>(name: Extract<keyof T, string>): string => name;
