import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state";

export type ErrorObject = {
    message: string;
    errorType: string;
    link?: string;
};
interface ErrorState {
    specificError: Record<string, ErrorObject | undefined>;
    currentError?: ErrorObject;
}
export const sliceName = "errors";

const initialState: ErrorState = {
    specificError: {}
};

export const errorSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        setError: (state, action: PayloadAction<ErrorObject>) => {
            state.currentError = action.payload;
        },
        setSpecificError: (state, action: PayloadAction<{ key: string; error?: ErrorObject }>) => {
            state.specificError[action.payload.key] = action.payload.error;
        },
        clearError: (state) => {
            state.currentError = undefined;
        },
        clearSpecificError: (state, action: PayloadAction<{ key: string }>) => {
            state.specificError[action.payload.key] = undefined;
        }
    }
});

export const { setError, clearError, setSpecificError, clearSpecificError } = errorSlice.actions;

export const selectError = (state: RootState) => state.errors;

export default errorSlice.reducer;
