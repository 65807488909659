import {
    Callout,
    DirectionalHint,
    FocusZone,
    IProgressIndicatorStyles,
    ISearchBoxProps,
    ProgressIndicator,
    SearchBox,
    IFocusZone,
    FocusZoneDirection,
    FocusZoneTabbableElements,
    Link,
    mergeStyleSets,
    getId,
    Text,
    ScrollablePane,
    getTheme,
    GroupedList,
    IGroup,
    DetailsRow,
    SelectionMode,
    IGroupHeaderProps,
    IconButton,
    Stack
} from "@fluentui/react";

import React, { useRef } from "react";
import SuggestionView from "./SuggestionView";
import { useAppDispatch, useAppSelector } from "../../common/state";
import { searchFilter, setSearchFilter, setSuggestionClicked } from "./slice";

export type Suggestion = {
    value: string;
    extra?: string;
    type: "Column" | "Name" | "Tag" | "Endpoint" | "Access Package";
};
type SuggestionSearchBoxProps = ISearchBoxProps & {
    onSuggestionClicked: (suggestion: Suggestion) => void;
    inProgress: boolean;
    debounceTime?: number;
};
const SuggestionSearchBox = (props: SuggestionSearchBoxProps) => {
    const textInput = React.useRef<HTMLDivElement>(null);
    const [isCalloutFocused, setCalloutFocused] = React.useState(false);
    const [isCallOutVisible, setIsCallOutVisible] = React.useState(false);
    const focusZoneRef = React.useRef<IFocusZone>(null);
    const [groups, setGroups] = React.useState<IGroup[]>([]);
    const { searchFilter: query, suggestionClicked, suggestions } = useAppSelector(searchFilter);
    const dispatch = useAppDispatch();

    const searchBoxRef = useRef<HTMLDivElement>(null);
    const focusZoneId = getId("focusZone");

    function setQuery(qry: string) {
        dispatch(setSearchFilter(qry));
    }
    React.useEffect(() => {
        const isCalloutVisible = !suggestionClicked && suggestions !== undefined && suggestions.length > 0;
        setIsCallOutVisible(isCalloutVisible);

        if (suggestions === undefined) {
            setQuery("");
        }
    }, [suggestions, suggestionClicked]);

    React.useEffect(() => {
        if (suggestions && suggestions.length > 0) {
            setGroups(getGroups());
        }
    }, [suggestions]);
    const ProgressIndicatorStyle: Partial<IProgressIndicatorStyles> = {
        itemProgress: {
            paddingBottom: "4px"
        }
    };
    // keep focus when scrolling
    React.useEffect(() => {
        if (searchBoxRef.current) {
            if (searchBoxRef.current === document.activeElement) {
                setIsCallOutVisible(true);
            } else {
                setIsCallOutVisible(false);
            }
        }
    }, [searchBoxRef.current]);
    const getCalloutWidth = () => {
        const calloutWidth = textInput.current?.offsetWidth;
        return calloutWidth + "px";
    };

    const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        if (suggestions && suggestions.length > 0 && !suggestionClicked) {
            if (!isCalloutFocused) {
                setIsCallOutVisible(true);
            }
        }
        if (props.onFocus) props.onFocus(event);
    };

    const onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.key === "ArrowDown") {
            setCalloutFocused(true);
            focusZoneRef.current?.focus();
            ev.preventDefault();
        } else {
            setCalloutFocused(false);
        }
    };

    const renderProgressIndicator = () => {
        if (props.inProgress) {
            return <ProgressIndicator styles={ProgressIndicatorStyle} />;
        }
        return null;
    };

    const onSuggestionClicked = (suggestion: Suggestion) => {
        const query = suggestion.value;
        dispatch(setSuggestionClicked(true));
        setQuery(query);
        props.onSuggestionClicked(suggestion);
        setIsCallOutVisible(false);
    };

    const getGroups = (): IGroup[] => {
        if (!suggestions) return [];

        const suggestionGroups = suggestions.reduce((group: Record<string, Suggestion[]>, suggestion) => {
            const { type } = suggestion;
            group[type] = group[type] ?? [];
            group[type].push(suggestion);
            return group;
        }, {});

        const grps = Object.keys(suggestionGroups).map((key, idx, arr) => ({
            key: key,
            name: key,
            startIndex: idx === 0 ? 0 : arr.slice(0, idx).reduce((acc, curr) => acc + suggestionGroups[curr].length, 0),
            count: suggestionGroups[key].length
        }));

        return grps;
    };

    const onRenderHeader = (props?: IGroupHeaderProps): JSX.Element | null => {
        if (props) {
            const toggleCollapse = (): void => {
                props.onToggleCollapse!(props.group!);
            };
            return (
                <Stack horizontal verticalAlign="center" className="separator">
                    <Text variant="mediumPlus" style={{ color: "rgb(0, 120, 212)" }}>
                        {props.group?.name}{" "}
                    </Text>

                    <IconButton
                        iconProps={{ iconName: props.group?.isCollapsed ? "ChevronRight" : "ChevronDown" }}
                        onClick={toggleCollapse}
                    />
                </Stack>
            );
        }

        return null;
    };
    const theme = getTheme();
    const allStyle = {
        textDecoration: "none",
        backgroundColor: "#c7c7c7",
        color: "black"
    };
    const suggestionStyle = mergeStyleSets({
        root: {
            width: "100%",
            color: "black",
            "&:hover": allStyle,
            "&:active": allStyle,
            "&:focus": allStyle
        }
    });

    const getDefaultListItem = (suggestion: Suggestion) => {
        return (
            <Link onClick={(e) => onSuggestionClicked(suggestion)} className={suggestionStyle.root} role="link">
                <SuggestionView suggestion={suggestion} filter={query}></SuggestionView>
            </Link>
        );
    };

    const onRenderCell = (
        nestingDepth?: number,
        item?: Suggestion,
        itemIndex?: number,
        group?: IGroup
    ): React.ReactNode => {
        return item && typeof itemIndex === "number" && itemIndex > -1 ? (
            <DetailsRow
                columns={[
                    {
                        key: "value",
                        fieldName: "value",
                        name: "value",
                        minWidth: 300,
                        onRender: (item) => getDefaultListItem(item)
                    }
                ]}
                styles={{ root: { width: "100%" } }}
                selectionMode={SelectionMode.none}
                indentWidth={0}
                groupNestingDepth={nestingDepth}
                item={item}
                itemIndex={itemIndex}
                compact={true}
                group={group}
            />
        ) : null;
    };

    const onCallOutDismiss = () => {
        dispatch(setSuggestionClicked(true));
        setIsCallOutVisible(false);
    };

    return (
        <>
            <div ref={textInput} className={props.className}>
                <SearchBox
                    {...props}
                    autoComplete="off"
                    placeholder="To Explore or Request Access to SIP Data, Search by Dataset Name, Tag, Column, Access Package or Endpoint"
                    onFocus={onFocus}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            setCalloutFocused(false);
                            setIsCallOutVisible(false);
                        }
                    }}
                    onKeyDown={onKeyDown}
                    ref={searchBoxRef}
                    value={query}
                ></SearchBox>
                {!suggestions ||
                    (suggestions.length === 0 && query && !props.inProgress && (
                        <div style={{ textAlign: "center", marginTop: "15px" }}>
                            <Text>No results found for {query}</Text>
                        </div>
                    ))}
            </div>

            {isCallOutVisible && (
                <Callout
                    style={{ width: getCalloutWidth() }}
                    isBeakVisible={false}
                    target={textInput.current}
                    onDismiss={onCallOutDismiss}
                    directionalHint={DirectionalHint.bottomAutoEdge}
                    directionalHintFixed
                    directionalHintForRTL={DirectionalHint.bottomAutoEdge}
                    setInitialFocus={isCalloutFocused}
                    shouldDismissOnWindowFocus={false}
                    preventDismissOnEvent={(ev) => (ev.type === "scroll" ? true : false)}
                    doNotLayer={true}
                >
                    {renderProgressIndicator()}

                    <div style={{ height: "80vh", position: "relative", maxHeight: "inherit" }}>
                        <ScrollablePane
                            styles={{
                                root: {
                                    border: "1px solid " + theme.palette.neutralLight
                                }
                            }}
                        >
                            <FocusZone
                                direction={FocusZoneDirection.bidirectional}
                                handleTabKey={FocusZoneTabbableElements.all}
                                id={focusZoneId}
                                componentRef={focusZoneRef}
                            >
                                <GroupedList
                                    styles={{ root: { width: "100%" } }}
                                    selectionMode={SelectionMode.none}
                                    items={suggestions || [{ value: "" }]}
                                    onRenderCell={onRenderCell}
                                    groups={groups}
                                    groupProps={{ onRenderHeader }}
                                    compact={true}
                                />
                            </FocusZone>
                        </ScrollablePane>
                    </div>
                </Callout>
            )}
        </>
    );
};

export default SuggestionSearchBox;
