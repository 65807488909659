import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authenticationParameters, authProvider } from "../auth";
import { RootState } from "./rootReducer";

export const adlsIngestionTrendApi = createApi({
    reducerPath: "adlsIngestionTrendAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
        prepareHeaders: async (headers, {}) => {
            await authProvider.acquireTokenSilent(authenticationParameters).then((token) => {
                if (token) {
                    // If we have a token set in state, let's assume that we should be passing it.
                    if (token.accessToken) {
                        headers.set("authorization", `Bearer ${token.accessToken}`);
                    }
                }
            });
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getAdlsIngestionTrendData: builder.query({
            query: (apiURL: string) => `${apiURL}`,
            keepUnusedDataFor: 60 // invalidate after 60 seconds
        })
    })
});

export const { useGetAdlsIngestionTrendDataQuery } = adlsIngestionTrendApi;
export const adlsIngestionTrend = (state: RootState) => state.adlsIngestionTrendAPI;
