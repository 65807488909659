import { IStyle, mergeStyleSets, Text, Link, IconButton, Stack, FontIcon } from "@fluentui/react";
import { ReactElement } from "react";
import Logo from "../../assets/images/HelpPanelLogo.png";

const _Styles = mergeStyleSets({
    helpSampleLink: [
        {
            fontSize: "14px",
            fontWeight: 600,
            margin: "8px 0",
            display: "block"
        }
    ] as IStyle
});

export function getHelpPanelContent(): ReactElement {
    const year = new Date().getFullYear();
    return (
        <div>
            <Stack horizontal>
                <Link
                    href="https://microsoftit.visualstudio.com/OneITVSO/_wiki/wikis/OneITVSO.wiki/52362/SIP-Data-Catalog"
                    className={_Styles.helpSampleLink}
                    target="_blank"
                >
                    SIP Data Catalog Wiki <FontIcon iconName="BookAnswers" />
                </Link>
            </Stack>
            <Stack horizontal>
                <Link
                    href="https://microsoftit.visualstudio.com/OneITVSO/_wiki/wikis/OneITVSO.wiki/54689/SIP-NGRBAC"
                    className={_Styles.helpSampleLink}
                    target="_blank"
                >
                    NGRBAC Wiki for Dataset Access Request Process <FontIcon iconName="BookAnswers" />
                </Link>
            </Stack>
            <Link href="mailto:ISRMDataPFTeam@microsoft.com" className={_Styles.helpSampleLink}>
                Contact
            </Link>
            <img alt="logo" src={Logo} width={260} style={{ paddingBottom: 10 }}></img>
            <br></br>
            <Text>By using SIP Data Catalog you consent to the collection of Usage Data.</Text>
            <Link
                href="https://privacy.microsoft.com/en-us/privacystatement"
                target="_blank"
                className={_Styles.helpSampleLink}
            >
                Microsoft Privacy Statement
            </Link>
            <Text block>&#169; Microsoft Corporation.</Text>
            <Text block>All rights reserved. {year}</Text>
        </div >
    );
}
