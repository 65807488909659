/**
 * Converts input properties of { string : object } pair to flat array
 * @param inputProps input properties of { string : object } pair
 */
export function flatProperties(inputProps: { [key: string]: unknown } | null | undefined): string[] | null | undefined {
    if (!inputProps) {
        return [];
    }

    const retVal: string[] = [];
    Object.keys(inputProps).forEach(function (key: string, index: number) {
        retVal[index] = key + ":" + String(inputProps[key]);
    });

    return retVal;
}

export enum CoherenceEventName {
    activation = "Site activated",
    navigation = "Site navigation"
}
