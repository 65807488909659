import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state";
import { Suggestion } from "./SuggestionSearchBox";

type SearchFilterState = {
    searchFilter: string;
    suggestionClicked: boolean;
    suggestions: Suggestion[];
};

const initialState: SearchFilterState = {
    searchFilter: "",
    suggestionClicked: false,
    suggestions: []
};

const searchFilterSlice = createSlice({
    name: "searchFilter",
    initialState,
    reducers: {
        setSearchFilter(state, action: PayloadAction<string | undefined>) {
            if (action.payload) {
                state.searchFilter = action.payload.trim();
            } else {
                state.searchFilter = "";
            }
        },
        setSuggestionClicked(state, action: PayloadAction<boolean>) {
            state.suggestionClicked = action.payload;
        },
        setSuggestions(state, action: PayloadAction<Suggestion[]>) {
            state.suggestions = action.payload;
        },
        clearSuggestions(state) {
            state.suggestions = [];
        }
    }
});

export const { setSearchFilter, setSuggestionClicked, setSuggestions, clearSuggestions } = searchFilterSlice.actions;
export default searchFilterSlice.reducer;
export const searchFilter = (state: RootState) => state.searchFilter;
