import { SelectionMode } from "@fluentui/react";
import { useEffect, useState } from "react";
import { ShimmeredList } from "../../../common/components/ShimmerList";
import { useAppDispatch } from "../../../common/state";
import { setError } from "../../../common/state/errorReducer";
import { ISchema } from "../../RenderTableView";
import { DataSetDetailProps } from "../DataSetDetail.types";
import { useGetSchemaQuery } from "./slice";
import { downloadContent, getColumns } from "./utils";
import { DownloadSchemaButton } from "./DownloadSchemaButton";

function Schema({ dataset, type }: DataSetDetailProps & { type: string }) {
    const [schema, setSchema] = useState<ISchema[]>();
    const [error, _setError] = useState<boolean>();
    const dispatch = useAppDispatch();

    let apiURL = `api/datasets/${type}/schema/${type === "sdw" ? dataset.sdwId : dataset.kustoId}`;
    const { data, isLoading } = useGetSchemaQuery(apiURL, { pollingInterval: 600000 });

    useEffect(() => {
        setSchema(undefined);
        getSchema();
    }, [type, data]);

    async function getSchema() {
        try {
            setSchema(undefined);
            _setError(false);
            if (!isLoading && data) setSchema(data);
        } catch (error) {
            _setError(true);
            dispatch(
                setError({ message: `An error occurred getting schema for ${dataset.name}`, errorType: "Schema" })
            );
        }
    }

    // SDW, Kusto Schema - Download
    function downloadSchema(schema: ISchema[], filename: string) {
        try {
            _setError(false);
            const schemaItems: { name: string; type: string }[] = [];
            if (schema) {
                schema.forEach((row) => {
                    schemaItems.push({ name: row.name, type: row.type });
                });
            }
            const header = [{ name: "Name", type: "Type" }];
            const tempRows = [...header, ...schemaItems];
            const csvRows: any = [];
            csvRows.push(
                tempRows
                    .map((it) => {
                        return Object.values(it).toString();
                    })
                    .join("\n")
            );
            downloadContent(csvRows, filename);
        } catch (e) {
            _setError(true);
            dispatch(
                setError({ message: `An error occurred downloading schema for ${dataset.name}`, errorType: "Schema" })
            );
        }
    }

    return (
        <>
            {schema && (
                <DownloadSchemaButton onClick={() => downloadSchema(schema, dataset.name ? dataset.name : "Schema")} />
            )}
            <ShimmeredList
                height="65vh"
                selectionMode={SelectionMode.none}
                columns={getColumns()}
                items={schema ? schema.map((x) => ({ name: x.name, value: x.type })) : []}
                enableShimmer={isLoading}
            />
        </>
    );
}

export { Schema };
