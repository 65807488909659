import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authenticationParameters, authProvider } from "../../../common/auth";
import { IDataQualityMetrics } from "../../RenderTableView";

export const getDataQualityApi = createApi({
    reducerPath: "dataQualityAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
        prepareHeaders: async (headers, { }) => {
            await authProvider.acquireTokenSilent(authenticationParameters).then((token) => {
                if (token) {
                    if (token.accessToken) {
                        headers.set("authorization", `Bearer ${token.accessToken}`);
                    }
                }
            });
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getDataQuality: builder.query<IDataQualityMetrics[], string>({
            query: (apiURL: string) => `${apiURL}`,
            keepUnusedDataFor: 60
        })
    })
});

export const { useGetDataQualityQuery, usePrefetch } = getDataQualityApi;
