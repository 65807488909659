import { useEffect, useState } from "react";
import "./SIPPYChat.css";
import { ChoiceGroup, FontIcon, IChoiceGroupOption, Icon, Link, PrimaryButton, Spinner, TextField, Toggle } from "@fluentui/react";
import axios from "axios";
import { useLocation } from "react-router-dom";

import { SippyChatResponse } from "./SippyChatTypes";
import { clearChatHistory, selectSippyChatStates, setChatHistory, setChatSessionId, setShowChatSessionHistory } from "../../common/state/sippyChatReducer";
import { useAppDispatch, useAppSelector } from "../../common/state";

import { getUserName } from "../../common/auth";
import { dataCatalogClient } from "../../common/clients";
import Markdown from "./Markdown";
import { ResponseFooter } from "./ResponseFooter";
import Sippy from "./assets/images/Sippy-Tab-V1.png";

const maxHistoryCount: number = 3;

const CancelToken = axios.CancelToken;
let source = CancelToken.source();
export function SippyChatBot({ datasetName, containerWidth = "auto" }: { datasetName: string, containerWidth?: string }) {

    const dispatch = useAppDispatch();
    const location = useLocation();
    const [message, setMessage] = useState("");
    const [chats, setChats] = useState<any[]>([]);
    const [chatContext, setChatContext] = useState<string>("");
    const [contextSelection, setContextSelection] = useState<string>("0");
    const [dataContext, setDataContext] = useState<string | null>("");
    const [placeHolderText, setPlaceHolderText] = useState<string>("");

    const [faqs, setFaqs] = useState<any[]>([]);
    const [followUpQus, setFollowUpQus] = useState<any[]>([]);
    const [usrQryHistory, setUsrQryHistory] = useState<string[]>([]);
    const [responseHistory, setResponseHistory] = useState<string[]>([]);
    const [isFetching, setIsFetching] = useState(false);

    const { chat: chatHistory, chatSID, showChatSessionHistory } = useAppSelector(selectSippyChatStates);
    let qus = faqs;
    if (location.pathname === "/" && faqs.length == 0) {
        qus.push("Do you have AAD Device data?");
        qus.push("How do I know what consumption endpoints are available for any dataset?");
        qus.push("What do the numbers in each data card on the home page mean?");
        setFaqs(qus);
    }
    else if (faqs.length == 0) {
        qus.push("Tell me more about this dataset");
        qus.push("What consumption endpoints are available?");
        qus.push("What is the SLA for this?");
        setFaqs(qus);
    }

    useEffect(() => {
        var elem = document.getElementsByClassName('chatContainer')[0];
        if (elem && elem !== null) {
            elem.scrollTop = elem?.scrollHeight;
        }
    }, [isFetching]);

    useEffect(() => {
        if (chatSID === undefined) {
            dispatch(setChatSessionId(getUserName().split('@')[0] + "_" + Date.now()))
        }
    }, [chatSID]);

    useEffect(() => {

        if (location.pathname === "/" || chatContext === null) {
            setDataContext(null)
            setPlaceHolderText("Ask me anything (be specific about dataset for better response)...");
            setChatContext("All Datasets");
        }
        else if (location.pathname.indexOf("/dataset") !== -1) {
            setDataContext(datasetName)
            setPlaceHolderText(`Ask me anything about ${datasetName}...`);
            setChatContext(datasetName);
        }
    }, [location.pathname]);


    const chat = async (e, message: string) => {

        var elem = document.getElementsByClassName('chatContainer')[0];
        if (elem && elem !== null) {
            elem.scrollTop = elem?.scrollHeight;
        }
        e.preventDefault();
        if (!message) return;
        setIsFetching(true);

        let msgs = chats;
        const qID = "Q_" + Math.random().toString(16).slice(2)
        msgs.push({ role: "user", content: message, sessionId: chatSID, qID: qID });
        setChats(msgs);

        // setting chat history in redux store
        dispatch(setChatHistory({ role: "user", content: message, sessionId: chatSID, qID: qID }));

        setMessage("");

        const req = {
            "datacontext": dataContext,
            "userAlias": getUserName().split('@')[0],
            "sessionId": chatSID,
            "questionId": qID,
            "userQuery": message,
            "userQueryHistory": usrQryHistory.slice(-1 * maxHistoryCount).reverse(),
            "aiResponseHistory": responseHistory.slice(-1 * maxHistoryCount)
        }

        try {
            const { data, status } = await dataCatalogClient.post<SippyChatResponse>(`datacatalogfeature/SippyChatService`, req, { cancelToken: source.token });
            if ((status === 200 || status === 300 || status === 400) && data && data["response"].length !== 0) {
                const resHis: string[] = responseHistory;
                resHis.push(data["response"]);
                if (data["followupQuestions"]) {
                    const tmpFQus: string[] = []
                    const fQusList: string[] = data["followupQuestions"] ? JSON.parse(data["followupQuestions"]) : [];
                    fQusList.map((fQus) => {
                        tmpFQus.push(fQus);
                    });
                    setFollowUpQus(tmpFQus);
                }
                setResponseHistory(resHis);

                // Page level chat history
                msgs.push({ role: "system", content: data["response"], sessionId: chatSID, qID: qID });
                setChats(msgs);

                // setting chat history in redux store
                dispatch(setChatHistory({ role: "system", content: data["response"], sessionId: chatSID, qID: qID }));

                const qryHis: string[] = usrQryHistory;
                qryHis.push(message);
                setUsrQryHistory(qryHis);

                setIsFetching(false);

                elem = document.getElementsByClassName('chatContainer')[0];
                if (elem && elem !== null) {
                    elem.scrollTop = elem?.scrollHeight;
                }
            }
            else if (status === 204 || status === 500) {
                // Page level chat history
                msgs.push({ role: "system", content: "Something went wrong, can you please try again later. Status code: " + status, sessionId: chatSID, qID: qID });
                setChats(msgs);

                // setting chat history in redux store
                dispatch(setChatHistory({ role: "system", content: "Something went wrong, can you please try again later. Status code: " + status, sessionId: chatSID, qID: qID }));
                setIsFetching(false);
            }
        }
        catch (error: any) {
            setIsFetching(false);
            // reseting the cancel token
            source = CancelToken.source();
            if (axios.isCancel(error)) {
                msgs.push({ role: "system", content: error?.message, sessionId: chatSID, qID: qID });
                setChats(msgs);
                dispatch(setChatHistory({ role: "system", content: error?.message, sessionId: chatSID, qID: qID }));
            } else {
                // Page level chat history
                msgs.push({ role: "system", content: error?.message, sessionId: chatSID, qID: qID });
                setChats(msgs);
                dispatch(setChatHistory({ role: "system", content: error?.message, sessionId: chatSID, qID: qID }));
            }
        }
    }

    function resetStateValues() {
        dispatch(clearChatHistory())
        setChats([])
        setResponseHistory([])
        setUsrQryHistory([])
    }

    const options: IChoiceGroupOption[] = [
        { key: '0', text: chatContext },
        { key: '1', text: 'All Datasets' }
    ];

    const chatLookup = showChatSessionHistory ? chatHistory : chats;

    return <>
        <div key="divSippyChat" className="SIPChat" style={{ width: containerWidth }}>
            <section key="secSippyChat">
                {chatLookup.length ? chatLookup.map((chat, index) => (
                    <span key={index} id="" className={chat.role === "user" ? "user_msg" : "server_msg"}>
                        <span key={"span_" + index}>
                            <Markdown key={"markdown_" + index} markdown={chat.content.replace("```markdown", "")}></Markdown>
                            {chat.role !== "user" && <ResponseFooter rFooterId={index} qID={chat.qID} chatContent={chat.content} sessionId={chatSID} userAlias={getUserName().split('@')[0]} actionSelected={chat.actionSelected && chat.actionSelected} />}
                        </span>
                        {chat.role === "user" && <FontIcon key={"copy_" + index} title="copy" className="copyIcon" iconName={"copy"} style={{ color: "black", cursor: "pointer", fontSize: 18, marginLeft: 10, marginRight: 4 }} onClick={() => { navigator.clipboard.writeText(chat.content) }} />}
                    </span>
                ))
                    :
                    ""
                }

            </section>

            {(faqs.length !== 0 || followUpQus.length !== 0) &&
                <div className="faqSection" key="divfolqus" >
                    {followUpQus.length
                        ? followUpQus.map((fQus, index) => (
                            <span key={"fQusKey" + index} id={"fQus" + index} role="button" className="faqQuestion" onClick={(e) => { !isFetching && chat(e, fQus) }}>{fQus}</span>
                        ))
                        : faqs.map((faq, index) => (
                            <span key={"fQusKey" + index} id={"faq_" + index} role="button" className="faqQuestion" onClick={(e) => { !isFetching && chat(e, faq) }}>{faq}</span>
                        ))}
                </div>
            }
            <div className="contextSection" style={{ display: "flex", width: "100%" }}>
                <div style={{ display: "left", width: "50%" }}>
                    <Toggle
                        label={"Show session chat history"}
                        inlineLabel
                        defaultChecked={showChatSessionHistory}
                        onText="On"
                        offText="Off"
                        onChange={(_ev, checked) => { dispatch(setShowChatSessionHistory(checked ?? false)) }}
                    />
                </div>
                <div style={{ display: "right", textAlign: "right", width: "50%" }}>
                    {
                        location.pathname.toLowerCase().indexOf("/dataset") !== -1 && chatContext !== null ?
                            <ChoiceGroup
                                label="Current Context: "
                                options={options}
                                selectedKey={contextSelection}
                                onChange={(_e, option) => {
                                    setContextSelection(option?.key ?? "0")
                                    setPlaceHolderText(option?.key === "0" ? `Ask a question about this ${chatContext}` : "Ask me anything (be specific about dataset for better response)...")
                                    setDataContext(option?.key === "0" ? chatContext : null)
                                    setUsrQryHistory([])
                                    setResponseHistory([])
                                }}
                                styles={{
                                    root: { display: " inline-flex" },
                                    label: { display: "inline" },
                                    flexContainer:
                                        [
                                            {
                                                selectors: {
                                                    ".ms-ChoiceField": {
                                                        float: "left",
                                                        paddingRight: 10
                                                    }
                                                },
                                                display: " inline-flex"
                                            }
                                        ]
                                }}
                            />
                            :
                            <><span aria-label="Current Context" >Current Context <Icon style={{ cursor: "pointer" }} iconName="info" title="Response will be returned based on this context"></Icon> :</span> <span className="" aria-label={chatContext} role="contentinfo">{chatContext}</span></>
                    }
                </div>
            </div>

            <div className={isFetching ? "showLoading" : "hide"}>
                <span className="loading">
                    {isFetching ? <Spinner label="Generating answers for you..." ariaLive="assertive" labelPosition="right" styles={{ label: { color: 'white !important' } }} /> : ""}
                </span>
                <span className="loading">
                    {isFetching ? <><PrimaryButton onClick={() => { source.cancel('Operation canceled by the user.'); }} iconProps={{ iconName: "stop" }} styles={{ root: { height: 17 }, label: { fontWeight: 400, fontSize: 12 } }}> Stop Responding</PrimaryButton></> : ""}
                </span>
            </div>
            <form action="" onSubmit={(e) => chat(e, message)}>
                <TextField
                    styles={{

                        fieldGroup: {
                            borderRadius: 10,
                            border: "1px solid rgb(0, 120, 212)",
                            background: "#FFFFFF",
                            height: 60,
                            ":focus": { border: '0px' }
                        },
                        field: {
                            color: "black",
                            marginLeft: 20,
                            ":focus": { border: '0px' }
                        },
                        suffix: {
                            background: "none"
                        }
                    }}
                    type="text"
                    name="message"
                    value={message}
                    placeholder={placeHolderText}
                    onChange={(_ev, v) => setMessage(v || "")}
                    iconProps={{ iconName: "chat", styles: { root: { color: 'gray', right: 'auto', top: 23, marginLeft: 5 } } }}
                    maxLength={500}
                    disabled={isFetching}
                    onRenderSuffix={() => { return <PrimaryButton aria-label="Send" role="button" disabled={isFetching} onClick={(e) => chat(e, message)} iconProps={{ iconName: "Send" }} styles={{ root: { minWidth: 30, width: 20 } }}></PrimaryButton> }}
                />
                <div style={{ margin: "8px 0px 8px 0px", height: 15 }}>{chatHistory.length !== 0 && < Link style={{ float: "left", fontSize: 13 }} disabled={isFetching} onClick={() => { resetStateValues() }}>Clear</Link>}<span style={{ float: 'right', color: 'gray', fontSize: 13 }} >{message.length} / 500</span></div>
            </form>
        </div >

    </>
}



