import {
    Stack,
    DocumentCard,
    DocumentCardTitle,
    IStackTokens,
    DocumentCardDetails,
    FontWeights,
    DefaultButton,
    Text,
    TooltipHost,
    FontIcon,
    Icon,
    Shimmer
} from "@fluentui/react";

import { ReactComponent as StorageAccountIcon } from "../../../assets/images/storageAccount.svg";
import { DataSetSummary } from "../DataSetSummary.types";

import { useEffect, useState } from "react";
import Background from "../../../assets/images/cardbkg.png";
import { useNavigate } from "react-router-dom";
import { getBase64Image } from "./DataSetUtils";
import { ReactComponent as SdwIcon } from "../../../assets/images/sdw.svg";
import { ReactComponent as AdxIcon } from "../../../assets/images/adx.svg";
import ICMIcon from "../../../assets/images/ICMIcon.png";
import debounce from "lodash.debounce";

import { TagSet } from "./TagSet";
import { CardDimensionProps, cardStackStyles, getCardStyles, getEmptyCards, loadingClassNames } from "./utils";
import { usePreloadImages } from "../../../common/hooks/usePreloadImages";
import { usePrefetch } from "../slice";

import { usePrefetch as useCollectionPrefetch } from "../../DataSetCollections/slice";
import { SortByProps } from "../DatasetsView";
import { useGetPageViewsDetailsQuery } from "../../../common/state/pageviewReducer";
import { useAppDispatch, useAppSelector } from "../../../common/state";
import { selectFavoriteDatasets, setFavoriteDatasets } from "../../../common/state/favoriteDatasetReducer";
import { dataCatalogClient } from "../../../common/clients";
export type CardGridProps = {
    dataSets?: DataSetSummary[];
    loading: boolean;
    error?: string;
    sortByProps?: SortByProps;
    isCollectionView?: boolean;
    sortCompare?: (a: DataSetSummary, b: DataSetSummary) => number;
};

const images = [Background];
const cardDimensionProps: CardDimensionProps = {
    cardHeight: 310,
    cardWidth: 375,
    cardMaxWidth: 400
};

export function DatasetCardGrid({ dataSets, error, sortByProps, sortCompare, isCollectionView }: CardGridProps) {
    const dispatch = useAppDispatch();
    const [elements, setElements] = useState(getEmptyCards(cardDimensionProps));
    const [imgLoaded, setImageLoaded] = useState(false);
    const { isLoading, isSuccess } = useGetPageViewsDetailsQuery("pageViewsAPI");
    const navigate = useNavigate();
    const prefetchDataset = usePrefetch("getDataSet");

    const prefetchCollection = useCollectionPrefetch("getDatasetCollection");
    const { favoriteDatasets, isLoading: isFavDatasetsLoading } = useAppSelector(selectFavoriteDatasets);
    usePreloadImages(images);

    const sectionStackTokens: IStackTokens = { childrenGap: 10 };
    const wrapStackTokens: IStackTokens = { childrenGap: 30 };

    const accessToolTip = "accessToolTip";

    function getTags(dataSet: DataSetSummary) {
        if (dataSet.datasetTags) {
            return (
                <Stack.Item>
                    <TagSet names={Array.from(new Set(dataSet.datasetTags))} />
                </Stack.Item>
            );
        } else {
            return <></>;
        }
    }
    // Method to handle favorite and unfavorite datasets
    async function datasetFavorites(dsId: number, isFavorite: boolean) {
        let curFavorites: number[] = favoriteDatasets ? favoriteDatasets : [];
        let response;
        if (isFavorite) {
            response = await dataCatalogClient.get(`datasets/favorite/${dsId}`);
            curFavorites = [...curFavorites, dsId]; // adding new dataset
        } else {
            response = await dataCatalogClient.get(`datasets/unfavorite/${dsId}`);
            curFavorites = curFavorites.filter((e) => e !== dsId); // removing dataset
        }
        if (response && response.status == 200) {
            // updating state with right datasets in favorite list
            dispatch(setFavoriteDatasets(curFavorites));
        }
    }

    useEffect(() => {
        if (dataSets && !error) {
            // can't sort readonly array, have to sort clone
            const dsCards = [...dataSets].sort(sortCompare).map((e) => {
                return (
                    <Stack.Item key={e.id}>
                        <DocumentCard
                            aria-label={e.description}
                            onClick={() => navigate(`/dataset/${e.id}`)}
                            styles={getCardStyles(cardDimensionProps)}
                            onMouseEnter={debounce(() => {
                                prefetchDataset(e.id, { ifOlderThan: 500 });
                                prefetchCollection(e.accessPackageId, { ifOlderThan: 1200 });
                            }, 1000)}
                        >
                            <Stack style={{ height: 154 }}>
                                <Stack.Item>
                                    <img
                                        height={150}
                                        width={375}
                                        className={imgLoaded ? loadingClassNames.loaded : loadingClassNames.loading}
                                        onLoad={(_) => {
                                            setImageLoaded(true);
                                        }}
                                        role="presentation"
                                        src={imgLoaded ? Background : getBase64Image()}
                                    />
                                </Stack.Item>
                                {!isFavDatasetsLoading && !isCollectionView && (
                                    <Stack.Item style={{ position: "relative", top: -152, right: -348, width: 25 }}>
                                        <FontIcon
                                            aria-describedby={accessToolTip + e.id}
                                            iconName={e.isFavorite ? "FavoriteStarFill" : "FavoriteStar"}
                                            onClick={(ev) => {
                                                datasetFavorites(e.id, !e.isFavorite);
                                                ev.stopPropagation();
                                            }}
                                            title={
                                                e.isFavorite
                                                    ? "Remove this dataset from my favorites"
                                                    : "Add this dataset to my favorites"
                                            }
                                            style={{ fontSize: "18px", color: "#F6C654" }}
                                        />
                                    </Stack.Item>
                                )}
                            </Stack>

                            <DocumentCardDetails>
                                <Stack horizontal horizontalAlign="space-between">
                                    <Stack style={{ marginLeft: "10px" }} tokens={{ childrenGap: 10 }} horizontal>
                                        {e.hasAdls && (
                                            <Stack.Item>
                                                <StorageAccountIcon title="Available in ADLS" />
                                            </Stack.Item>
                                        )}
                                        {e.hasKusto && (
                                            <Stack.Item>
                                                <AdxIcon title="Available in Kusto" />
                                            </Stack.Item>
                                        )}
                                        {e.hasSDW && (
                                            <Stack.Item>
                                                <SdwIcon title="Available in SQL" />
                                            </Stack.Item>
                                        )}
                                    </Stack>
                                    <Stack style={{ marginRight: "10px" }} tokens={{ childrenGap: 10 }} horizontal>
                                        <TooltipHost
                                            content={
                                                <Text block>
                                                    {e.my
                                                        ? "You have access to this dataset"
                                                        : "You don't have access to this datasets"}
                                                </Text>
                                            }
                                            id={accessToolTip + e.id}
                                            calloutProps={{ gapSpace: 0 }}
                                        >
                                            {e.activeICMs && e.activeICMs.length != 0 && (
                                                <img
                                                    src={ICMIcon}
                                                    alt="Active ICM"
                                                    aria-label={"Active ICM"}
                                                    style={{ width: "25px", paddingRight: "3px" }}
                                                ></img>
                                            )}
                                            <FontIcon
                                                aria-describedby={accessToolTip + e.id}
                                                iconName={e.my ? "UnlockSolid" : "LockSolid"}
                                                style={{ fontSize: "18px", color: e.my ? "green" : "red" }}
                                            />
                                        </TooltipHost>
                                    </Stack>
                                </Stack>

                                <DocumentCardTitle
                                    styles={{
                                        root: {
                                            height: "22px",
                                            fontWeight: FontWeights.bold,
                                            fontFamily: "Helvetica "
                                        }
                                    }}
                                    title={e.name}
                                />

                                <Stack style={{ padding: "4px", minHeight: "44px" }} horizontal horizontalAlign="start">
                                    {getTags(e)}
                                </Stack>
                                {!isCollectionView && (
                                    <Stack horizontal horizontalAlign="space-between">
                                        <Stack horizontal>
                                            <Stack.Item style={{ margin: "auto" }}>
                                                {/*<DocumentCardActions actions={[]} views={e.hitCount} />*/}
                                                {/*rendering custom view to get 0 as value when no hit count is available*/}
                                                {isLoading ? (
                                                    <Shimmer width={"30px"} style={{ paddingLeft: 10 }} />
                                                ) : isSuccess ? (
                                                    <p aria-label={"views"}>
                                                        <Icon
                                                            iconName="View"
                                                            style={{
                                                                padding: "4px 12px",
                                                                paddingRight: "8px",
                                                                position: "relative"
                                                            }}
                                                        />
                                                        <span style={{ top: -2, position: "relative" }}>
                                                            {e.hitCount}
                                                        </span>
                                                    </p>
                                                ) : (
                                                    <></>
                                                )}
                                            </Stack.Item>
                                        </Stack>

                                        <Stack.Item align="end">
                                            <DefaultButton
                                                disabled={e.isPlaceholder}
                                                style={{ margin: "5px" }}
                                                text="Request Access"
                                                onClick={(ev) => {
                                                    ev.stopPropagation();
                                                    navigate(`/collections/${e.accessPackageId}`);
                                                }}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                )}
                            </DocumentCardDetails>
                        </DocumentCard>
                    </Stack.Item>
                );
            });
            setElements(dsCards);
        } else if (error) {
            setElements([]);
        }
    }, [dataSets, imgLoaded, sortByProps, error]);

    return (
        <Stack enableScopedSelectors tokens={sectionStackTokens}>
            <Stack
                enableScopedSelectors
                horizontalAlign="center"
                horizontal
                wrap
                styles={cardStackStyles}
                tokens={wrapStackTokens}
            >
                {elements}
            </Stack>
        </Stack>
    );
}
