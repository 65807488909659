import { Label, FontSizes, Separator, Stack, IconButton, IChoiceGroupOption, getTheme, Text } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { Contexts, contextMap } from "../../../common/models/ConsumptionContext";
import { DataSetDetail } from "../DataSetDetail.types";

const ConsumptionLayerSelector = ({
    currentDataSet,
    context,
    setContext
}: {
    currentDataSet?: DataSetDetail;
    context: Contexts;
    setContext: React.Dispatch<React.SetStateAction<Contexts>>;
}) => {
    const [contextOptions, setContextOptions] = useState<IChoiceGroupOption[]>();
    const theme = getTheme();

    useEffect(() => {
        if (currentDataSet) {
            const availableIn: IChoiceGroupOption[] = [];
            if (currentDataSet.hasAdls) availableIn.push(contextMap.ADLS);
            if (currentDataSet.hasKusto) availableIn.push(contextMap.Kusto);
            if (currentDataSet.hasSDW) availableIn.push(contextMap.SQL);
            setContextOptions(availableIn);
            if (availableIn.length != 0) {
                let defaultSel: IChoiceGroupOption = availableIn[0];
                // Setting first option as default selection
                setContext(defaultSel.key as Contexts);
            }
        }
    }, [currentDataSet]);
    return (
        <>
            {" "}
            <Label style={{ fontSize: FontSizes.size12 }}>Select Consumption Layer</Label>
            <Separator>
                <Stack horizontal>
                    {contextOptions &&
                        contextOptions.map((option) => (
                            <Stack key={option.text} horizontalAlign="center">
                                <IconButton
                                    title={option.text}
                                    styles={{
                                        root: {
                                            marginRight: "2px",
                                            boxSizing: "content-box",
                                            border:
                                                context === option.key ? `1px solid ${theme.palette.accent}` : "none",
                                            background: context === option.key ? theme.palette.neutralLight : "none"
                                        }
                                    }}
                                    onClick={() => setContext(option.key as Contexts)}
                                    iconProps={option.iconProps}
                                />
                                <Text style={{ fontWeight: "500" }} variant="xSmall">
                                    {option.text}
                                </Text>
                            </Stack>
                        ))}
                </Stack>
            </Separator>
        </>
    );
};

export default ConsumptionLayerSelector;
