import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state";
import { dataCatalogClient } from "../clients";
import { ChatBotfeedback } from "../../features/SippyChatBot/SippyChatTypes";
import axios from "axios";

export type chatObj = { role: string, content: string, sessionId: string | undefined, qID: string, actionSelected?: string }
interface sippyChatState {
    chatSID: string | undefined
    chat: chatObj[]
    followUpQus: string[]
    usrQryHistory: string[]
    responseHistory: string[]
    showChatSessionHistory: boolean
    isFetching?: boolean;
    errors?: string;
    isFetchingChatResponse?: boolean;
    isErrorChatResponse?: string;
    isSippyFeatureEnabled?: boolean;
}
export const sliceName = "sippychat";

const initialState: sippyChatState = {
    chatSID: undefined,
    chat: [],
    followUpQus: [],
    usrQryHistory: [],
    responseHistory: [],
    showChatSessionHistory: false,
    isFetching: false,
    errors: "",
    isSippyFeatureEnabled: false
};

// Get Favorite Datasets for the current user
export const postFeeback = createAsyncThunk(
    `${sliceName}/post_feedback`,
    async (objFeedback: ChatBotfeedback) => {
        try {
            return dataCatalogClient.post<void>("datacatalogfeature/PostChatBotFeedback", objFeedback);
        }
        catch (error: any) {
            return error;
        }
    }
);

export const IsSippyEnabledAction = createAsyncThunk(`${sliceName}/IsSippyFeatureEnabled`, async (configKey: String) => {
    const response = await dataCatalogClient.get(`datacatalogfeature/IsFeatureEnabled/${configKey}`);
    return response.data;
});

export const sippyChatSlice = createSlice({
    name: sliceName,
    initialState,
    extraReducers: (builder) => {
        builder.addCase(IsSippyEnabledAction.fulfilled, (state, _action) => {
            state.isSippyFeatureEnabled = _action.payload;
        });
    },
    reducers: {

        setChatSessionId: (state, action: PayloadAction<string>) => {
            state.chatSID = action.payload;
        },
        setChatHistory: (state, action: PayloadAction<chatObj>) => {
            state.chat.push(action.payload);
        },
        resetChatHistory: (state, action: PayloadAction<chatObj[]>) => {
            state.chat = action.payload;
        },
        setFollowUpQus: (state, action: PayloadAction<string[]>) => {
            state.followUpQus = action.payload;
        },
        setUsrQryHistory: (state, action: PayloadAction<string[]>) => {
            state.usrQryHistory = action.payload;
        },
        setResponseHistory: (state, action: PayloadAction<string[]>) => {
            state.responseHistory = action.payload;
        },

        setShowChatSessionHistory: (state, action: PayloadAction<boolean>) => {
            state.showChatSessionHistory = action.payload;
        },

        clearChatHistory: (state) => {
            state.chat = [];
        },
        clearFollowUpQus: (state) => {
            state.followUpQus = [];
        },
        clearUsrQryHistory: (state) => {
            state.usrQryHistory = [];
        },
        clearResponseHistory: (state) => {
            state.responseHistory = [];
        },
    }
});

export const { setChatSessionId, setChatHistory, resetChatHistory, setFollowUpQus, setUsrQryHistory, setShowChatSessionHistory, clearChatHistory, clearFollowUpQus, clearUsrQryHistory, clearResponseHistory } = sippyChatSlice.actions;
export const selectSippyChatStates = (state: RootState) => state.sippyChat
export default sippyChatSlice.reducer;
