import {
    Stack,
    Depths,
    DefaultButton,
    Separator,
    Text,
    mergeStyles,
    useTheme,
    ChoiceGroup,
    IChoiceGroupOption
} from "@fluentui/react";
import React, { useEffect } from "react";
import { DataSetOwnersDisplay } from "../../common/components/DataSetOwnersDisplay";
import { useIsSmallScreen } from "../../common/hooks/useIsSmallScreen";
import { useNavigate } from "react-router-dom";
import { oboRequest, selfRequest, oboRoleGroupRequest } from "../DataSets/DatasetCardGrid/utils";
import { AccessPackage, DatasetCollection } from "./DatasetCollection.type";
import { useAppSelector } from "../../common/state";
import { selectNotifications } from "../../common/state/notificationsReducer";

export type AccessPackageInfo = {
    collection: DatasetCollection;
    accessPackages: AccessPackage[];
    setCollection: React.Dispatch<React.SetStateAction<string>>;
};
const AccessRequestHeader = ({ collection, accessPackages, setCollection }: AccessPackageInfo) => {
    const smallScreen = useIsSmallScreen();
    const [selectedAccessPackage, setSelectedAccessPackage] = React.useState<AccessPackage>();
    const { isPreviewEnabled } = useAppSelector(selectNotifications);
    const [options, setOptions] = React.useState<IChoiceGroupOption[]>([
        ...accessPackages.map((ap) => ({ key: ap.id, text: ap.name }))
    ]);

    const { my } = collection;
    const theme = useTheme();
    const secondaryStyles = mergeStyles({
        fontSize: theme.fonts.mediumPlus.fontSize,
        fontWeight: theme.fonts.mediumPlus.fontWeight,
        color: theme.palette.neutralSecondary
    });
    const navigate = useNavigate();

    useEffect(() => {
        if (accessPackages && collection) {
            const hasKusto = collection.datasets.some((ds) => ds.hasKusto);
            setOptions([
                ...accessPackages.map((ap) => ({
                    key: ap.id,
                    text:
                        ap.isMultiDataset && hasKusto ? `${ap.name} (Select this option for access to Kusto)` : ap.name,
                    title: ap.name
                }))
            ]);
            if (!selectedAccessPackage) setSelectedAccessPackage(accessPackages[0]);
        }
    }, [accessPackages, collection]);

    function menuProps() {
        if (selectedAccessPackage) {
            const { id, name, refId } = selectedAccessPackage;
            const requests = [oboRequest(refId, name, navigate)];
            if (!my) requests.push(selfRequest(id));

            if (collection.roleGroup && collection.roleGroup.groupId)
                requests.push(oboRoleGroupRequest(id, navigate, collection.roleGroup.accessStatus));

            return {
                shouldFocusOnMount: true,
                items: requests
            };
        }
    }

    return (
        <Stack
            styles={{
                root: {
                    minWidth: "265px",
                    padding: "20px",
                    boxShadow: Depths.depth4,
                    backgroundColor: "offwhite",
                    width: "40%"
                }
            }}
        >
            <Stack horizontal={!smallScreen} horizontalAlign={!smallScreen ? "space-between" : "center"}>
                <Stack.Item style={{ width: "60%" }}>
                    <ChoiceGroup
                        label="AccessPackage"
                        options={options}
                        selectedKey={selectedAccessPackage?.id || "1"}
                        onChange={(_e, option) => {
                            setSelectedAccessPackage(accessPackages.find((x) => x.id === option?.key));
                            setCollection(option?.key as string);
                        }}
                    />
                </Stack.Item>

                <Stack.Item>
                    <DefaultButton text="Request Access" menuProps={menuProps()} />
                </Stack.Item>
            </Stack>
            <Separator />
            <Stack.Item style={{ marginBottom: "10px" }} align="start">
                <Text className={secondaryStyles}>
                    This access request will provide access the below dataset
                    {collection.datasets.length === 1 ? "" : "s"}.
                </Text>
            </Stack.Item>
            {selectedAccessPackage && !selectedAccessPackage.isPlaceHolder && (
                <DataSetOwnersDisplay accessPackageId={selectedAccessPackage.id} />
            )}
        </Stack>
    );
};

export default AccessRequestHeader;
