import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { setError, setSpecificError } from "./errorReducer";
import { TelemetryService } from "../../common/telemetry";

export const rtkErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    // clear last error on refetch
    if (
        action.type.endsWith("executeQuery/pending") &&
        action.meta &&
        action.meta.arg &&
        action.meta.arg.endpointName
    ) {
        api.dispatch(
            setSpecificError({
                key: action.meta.arg.endpointName,
                error: undefined
            })
        );
    }

    if (isRejectedWithValue(action)) {
        if (action.payload && action.payload.data) {
            let message = "";
            let errorType = "GenericError";
            let link: string | undefined = undefined;

            if (action.payload.data.title) {
                message = action.payload.data.title;
            } else if (typeof action.payload.data === "string") {
                message = action.payload.data;
            } else if (action.payload.data.error && action.payload.data.error.message) {
                message = action.payload.data.error.message;
            } else if (action.payload.data.message && action.payload.data.errorType) {
                message = action.payload.data.message;
                errorType = action.payload.data.errorType;
                link = action.payload.data.link;
            } else {
                message = "There was an error processing your request";
            }
            const errObj = { message, errorType, link };
            api.dispatch(setError({ message, errorType, link }));

            if (action.meta && action.meta.arg && action.meta.arg.endpointName) {
                const key = action.meta.arg.endpointName;
                api.dispatch(
                    setSpecificError({
                        key,
                        error: errObj
                    })
                );

                TelemetryService.trackException(new Error(message), { operation: key });
            } else {
                TelemetryService.trackException(new Error(message), { operation: "unknown" });
            }
        }
    }

    return next(action);
};
