import { ITelemetryConfig, ICustomProperties } from "./TelemetryService.types";
import {
    ApplicationInsights,
    IMetricTelemetry,
    IExceptionTelemetry,
    IEventTelemetry,
    ITraceTelemetry,
    ITelemetryItem,
    IPageViewTelemetry
} from "@microsoft/applicationinsights-web";
import { telemetryConfig } from ".";

class TelemetryService {
    //@ts-ignore
    private appInsights: ApplicationInsights;

    private logToConsole = process.env.NODE_ENV !== "production" && process.env.REACT_APP_LOG_TO_CONSOLE;

    //@ts-ignore
    private envelopeData: Record<string, unknown>;

    /**
     * Initializes the TelemetryService. Don't call any other TelemetryService functions before this one.
     * @param config the config
     */
    public initialize(config: ITelemetryConfig) {
        if (!config.AIKey) {
            throw new Error("Instrumentation key not provided");
        }

        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: config.AIKey
            }
        });

        this.appInsights.loadAppInsights();

        this.envelopeData = {
            AIKey: config.AIKey,
            EnvironmentName: config.EnvironmentName
        };
        this.appInsights.addTelemetryInitializer(this.telemetryInitializer);
    }

    private telemetryInitializer = (envelope: ITelemetryItem) => {
        if (!envelope.data) {
            return;
        }
        for (const key in this.envelopeData) {
            envelope.data[key] = this.envelopeData[key];
        }
    };

    /**
     * Log metric
     * Typically used to send regular reports of performance indicators.
     * @param metric            A string that identifies the metric.
     * @param customProperties  additional properties to send with the metric.
     */
    public trackMetric(metric: IMetricTelemetry, properties?: ICustomProperties) {
        if (this.logToConsole) {
            console.log(
                "[Telemetry - console] Log Metric: " + JSON.stringify(metric),
                ", " + JSON.stringify(properties)
            );
        } else {
            this.appInsights.trackMetric(metric, properties);
        }
    }
    private replaceErrors(key, value) {
        if (value instanceof Error) {
            var error = {};

            Object.getOwnPropertyNames(value).forEach(function (propName) {
                error[propName] = value[propName];
            });

            return error;
        }

        return value;
    }
    /**
     * Log an exception you have caught.
     * @param exception The exception to log.
     */
    public trackException(exception: Error, properties: ICustomProperties) {
        const error: IExceptionTelemetry = {
            exception: exception,
            properties: { ...properties }
        };

        if (this.logToConsole) {
            console.log("[Telemetry - console] Log Error: ", JSON.stringify(error, this.replaceErrors));
        } else {
            this.appInsights.trackException(error);
        }
    }

    /**
     * Log a page visit
     * @param pageView The page view details to log.
     */
    public trackPageView(pageView: IPageViewTelemetry) {
        if (this.logToConsole) {
            console.log("[Telemetry - console] Log Page View: ", JSON.stringify(pageView));
        } else {
            if (!this.appInsights) {
                this.initialize(telemetryConfig);
            }
            this.appInsights.trackPageView(pageView);
        }
    }

    /**
     * Log a user action or other occurrence.
     * @param event             the TelemetryEvent to send
     * @param customProperties  Optional additional properties on the event
     */
    public trackEvent(event: IEventTelemetry, properties?: ICustomProperties) {
        if (this.logToConsole) {
            console.log(
                "[Telemetry - console] Log Event: " + JSON.stringify(event) + ", " + JSON.stringify(properties)
            );
        } else {
            this.appInsights.trackEvent(event, properties);
        }
    }

    /**
     * Log a diagnostic message.
     * @param trace             A diagnostic trace to log
     * @param customProperties  Optional additional properties of form map[string, string] - used to filter traces in the portal.Defaults to empty.
     */
    public trackTrace(trace: ITraceTelemetry, properties?: ICustomProperties) {
        if (this.logToConsole) {
            console.log(
                "[Telemetry - console] Log Trace: " + JSON.stringify(trace) + ", " + JSON.stringify(properties)
            );
        } else {
            this.appInsights.trackTrace(trace, properties);
        }
    }

    /**
     * to see data sent immediately
     */
    public sendTelemetryNow() {
        this.appInsights.flush();
    }
}

export default new TelemetryService();
