import { DefaultEffects, IStackProps, Stack, mergeStyles } from "@fluentui/react";
import React from "react";

const StandardForm = ({
    children,
    widthPercent = 30,
    mediumWidthPercent = 50,
    smallWidthPercent = 80,
    ...props
}: {
    children: React.ReactNode;
    widthPercent?: number;
    smallWidthPercent?: number;
    mediumWidthPercent?: number;
} & IStackProps) => {
    const containerStyles = mergeStyles({
        width: `${widthPercent}%`,
        background: "#fff",
        borderRadius: DefaultEffects.roundedCorner2,
        padding: "35px",
        boxSizing: "content-box",
        boxShadow: DefaultEffects.elevation4,
        "@media (max-width: 1500px)": {
            width: `${mediumWidthPercent}%`
        },
        "@media (max-width: 800px)": {
            width: `${smallWidthPercent}%`
        }
    });
    return (
        <Stack {...props} styles={{ root: { margin: "50px 0" } }} horizontalAlign="center">
            <Stack className={containerStyles}>{children}</Stack>
        </Stack>
    );
};

export default StandardForm;
