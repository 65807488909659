import { SelectionMode } from "@fluentui/react";
import { useEffect, useState } from "react";
import { ShimmeredList } from "../../../common/components/ShimmerList";
import { useAppDispatch } from "../../../common/state";
import { setError } from "../../../common/state/errorReducer";
import { IDataQualityMetrics } from "../../RenderTableView";
import { DataSetDetailProps } from "../DataSetDetail.types";
import { getDataQualityColumns } from "../SchemaViews/utils";
import { useGetDataQualityQuery } from "./AdlsDataQuality";

function AdlsDataQuality({ dataset }: DataSetDetailProps) {
    const [schema, setSchema] = useState<IDataQualityMetrics[]>();
    const [error, _setError] = useState<boolean>();
    const dispatch = useAppDispatch();

    let apiURL = `api/datasets/getdataqualityadlsschema/${dataset.adlsId}`;
    const { data, isLoading } = useGetDataQualityQuery(apiURL, { pollingInterval: 600000 });

    useEffect(() => {
        setSchema(undefined);
        getSchema();
    }, [data]);

    async function getSchema() {
        try {
            setSchema(undefined);
            _setError(false);
            if (!isLoading && data) setSchema(data);
        } catch (error) {
            _setError(true);
            dispatch(
                setError({
                    message: `An error occurred getting DataQualityMetrics for ${dataset.name}`,
                    errorType: "AdlsDataQuality"
                })
            );
        }
    }

    return (
        <>
            <ShimmeredList
                height="60vh"
                selectionMode={SelectionMode.none}
                columns={getDataQualityColumns()}
                items={
                    schema
                        ? schema.map((x) => ({
                              columnName: x.columnName,
                              completenessPercent: x.completenessPercent,
                              uniquenessPercent: x.uniquenessPercent,
                              maxLength: x.maxLength
                          }))
                        : []
                }
                enableShimmer={isLoading}
            />
        </>
    );
}

export { AdlsDataQuality };
