import { Text, Stack } from "@fluentui/react";
import { Suggestion } from "./SuggestionSearchBox";

const SuggestionView = ({ suggestion, filter }: { suggestion: Suggestion; filter: string }) => {
    if (filter === "") return <Text>{suggestion.value}</Text>;
    try {
        const parts = suggestion.value.split(new RegExp(`(${filter})`, "gi"));

        return (
            <Stack key={suggestion.value} horizontal tokens={{ childrenGap: 5 }}>
                {suggestion.extra && (
                    <Stack.Item>
                        <Text style={{ color: "rgb(0, 120, 212)" }}>[{suggestion.extra}] -</Text>
                    </Stack.Item>
                )}
                <Stack.Item>
                    {parts.map((part, i) => (
                        <Text
                            key={`${i}`}
                            style={part.toLowerCase() === filter.toLowerCase() ? { fontWeight: "bold" } : {}}
                        >
                            {part}
                        </Text>
                    ))}
                </Stack.Item>
            </Stack>
        );
    } catch (error) {
        return <Text>{suggestion.value}</Text>;
    }
};

export default SuggestionView;
