import { Spinner } from '@fluentui/react';
import { IGroupedVerticalBarChartData, GroupedVerticalBarChart } from '@fluentui/react-charting';
import { useEffect, useState } from 'react';
import { useGetAdlsIngestionTrendDataQuery } from '../../../common/state/adlsIngestionTrendReducer';

function AdlsIngestionTrendReport({ filterId }: { filterId: number }) {
    const [reportData, setReportData] = useState<IGroupedVerticalBarChartData[]>([]);
    let apiURL = `api/datasets/getAdlsIngestionTrendData/${filterId}`
    const { data, isLoading } = useGetAdlsIngestionTrendDataQuery(apiURL, { pollingInterval: 600000 });

    // SDW Data Quality metrics
    useEffect(() => {
        if (!isLoading && data) {
            setReportData(data)
        }
    }, [data, isLoading]);


    return (
        <>
            {isLoading ?
                <b><Spinner style={{ color: "black" }} label="Please wait, loading..." /></b>
                :
                reportData && reportData.length != 0 ? <div style={{ width: '1300px', height: '400px' }}>
                    <GroupedVerticalBarChart
                        chartTitle="ADLS Ingestion Trend"
                        data={reportData}
                        yAxisTickCount={10}
                        barwidth={100}
                        width={1300}
                        styles={{ barLabel: { fontSize: 8} }}
                    />


                </div>
                    :
                    <div> Currently no data available to show. </div>
            }
        </>
    );
}

export { AdlsIngestionTrendReport };
