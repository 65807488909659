import { FC } from 'react'
import ReactMarkdown from 'react-markdown';
import rangeParser from 'parse-numeric-range';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import tsx from 'react-syntax-highlighter/dist/cjs/languages/prism/tsx';
import typescript from 'react-syntax-highlighter/dist/cjs/languages/prism/typescript';
import scss from 'react-syntax-highlighter/dist/cjs/languages/prism/scss';
import bash from 'react-syntax-highlighter/dist/cjs/languages/prism/bash';
import markdown from 'react-syntax-highlighter/dist/cjs/languages/prism/markdown';
import json from 'react-syntax-highlighter/dist/cjs/languages/prism/json';
import { ghcolors, gruvboxLight, lucario, nord, prism } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import "./SIPPYChat.css";

SyntaxHighlighter.registerLanguage('tsx', tsx);
SyntaxHighlighter.registerLanguage('typescript', typescript);
SyntaxHighlighter.registerLanguage('scss', scss);
SyntaxHighlighter.registerLanguage('bash', bash);
SyntaxHighlighter.registerLanguage('markdown', markdown);
SyntaxHighlighter.registerLanguage('json', json);

type MarkdownProps = {
    markdown: string;
};

const Markdown: FC<MarkdownProps> = ({ markdown }) => {

    const MarkdownComponents: object = {

        code({ node, inline, className, ...props }) {

            const hasLang = /language-(\w+)/.exec(className || '');
            const hasMeta = node?.data?.meta;

            const applyHighlights: object = (applyHighlights: number) => {
                if (hasMeta) {
                    const RE = /{([\d,-]+)}/;
                    const metadata = node.data.meta?.replace(/\s/g, '');
                    const strlineNumbers = RE?.test(metadata)
                        ? RE?.exec(metadata)?.length != 0 ? RE?.exec(metadata)?.length[1] : '0'
                        : '0';
                    const highlightLines = rangeParser(strlineNumbers);
                    const highlight = highlightLines;
                    const data: string | null = highlight.includes(applyHighlights)
                        ? 'highlight'
                        : null;
                    return { data };
                } else {
                    return {};
                }
            };

            return hasLang ? (
                <SyntaxHighlighter
                    style={prism}
                    language={hasLang[1]}
                    PreTag="div"
                    className="codeStyle"
                    showLineNumbers={true}
                    wrapLines={hasMeta}
                    useInlineStyles={true}
                    lineProps={applyHighlights}
                >
                    {props.children}
                </SyntaxHighlighter>
            ) : (
                <code {...props} />
            )
        },

        a({ node, ...props }) {
            return (
                <a href={props.href} target="_blank" rel="noreferrer">
                    {props.children}
                </a>
            );
        }
    }

    return (
        <>
            <ReactMarkdown
                className="markdownStyle"
                components={MarkdownComponents}
            >
                {markdown}
            </ReactMarkdown>
        </>
    )
}

export default Markdown;