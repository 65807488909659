import { useEffect, useState } from "react";
import { Feedback } from "./Feedback"; // adjust the import path as needed
import { SipMonModal } from "../../SipMonModal";

const FeedbackModal = ({ show, onClose }: { show: boolean; onClose: () => void }) => {
    const [displayFeedbackSuccess, setDisplayFeedbackSuccess] = useState(false);

    return (
        <>
            {show && (
                <SipMonModal
                    headerText={"Enter your Feedback"}
                    content={
                        <Feedback
                            onClose={() => {
                                onClose();
                                setDisplayFeedbackSuccess(true);
                            }}
                        />
                    }
                    isLoading={false}
                    onDismiss={() => onClose()}
                />
            )}

            {displayFeedbackSuccess && (
                <SipMonModal
                    headerText={"Feedback has been successfully Submitted!"}
                    content={<></>}
                    isLoading={false}
                    onDismiss={() => {
                        setDisplayFeedbackSuccess(false);
                    }}
                />
            )}
        </>
    );
};

export default FeedbackModal;
