import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authenticationParameters, authProvider } from "../../../common/auth";
import { ISchema } from "../../RenderTableView";

export const getSchemaApi = createApi({
    reducerPath: "schemaAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
        prepareHeaders: async (headers, {}) => {
            await authProvider.acquireTokenSilent(authenticationParameters).then((token) => {
                if (token) {
                    if (token.accessToken) {
                        headers.set("authorization", `Bearer ${token.accessToken}`);
                    }
                }
            });
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getSchema: builder.query<ISchema[], string>({
            query: (apiURL: string) => `${apiURL}`,
            keepUnusedDataFor: 60
        })
    })
});

export const { useGetSchemaQuery, usePrefetch } = getSchemaApi;
