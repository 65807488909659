import { AdlsDatasetContent } from "./AdlsDatasetContent";
import { KustoDatasetContent } from "./KustoDatasetContent";
import { SdwDatasetContent } from "./SdwDatasetContent";
import { DatasetContentProps } from "../DatasetCardGrid/Types";

function DatasetProperties({ currentDataSet, type }: DatasetContentProps & { type: "adls" | "kusto" | "sdw" }) {
    function renderProperties() {
        switch (type) {
            case "kusto":
                return <KustoDatasetContent currentDataSet={currentDataSet} />;
            case "sdw":
                return <SdwDatasetContent currentDataSet={currentDataSet} />;
            default:
                return <AdlsDatasetContent currentDataSet={currentDataSet} />;
        }
    }
    return <>{renderProperties()}</>;
}

export { DatasetProperties };
