import { DefaultButton, PrimaryButton, Stack, Text } from "@fluentui/react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { NavigateFunction } from "react-router-dom";

// temp function until names have been cleaned up in the db
function titleCase(str: string): string {
    str = str
        .split(".")
        .map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(".");

    str = str
        .split("_")
        .map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join("_");
    return str;
}

// modified from https://stackoverflow.com/a/14991797
function parseCSV(str: string) {
    const arr: string[][] = [[]];
    let quote = false; // 'true' means we're inside a quoted field

    // Iterate over each character, keep track of current row and column (of the returned array)
    for (let row = 0, col = 0, c = 0; c < str.length; c++) {
        let cc = str[c],
            nc = str[c + 1]; // Current character, next character
        arr[row] = arr[row] || []; // Create a new row if necessary
        arr[row][col] = arr[row][col] || ""; // Create a new column (start with empty string) if necessary

        // If the current character is a quotation mark, and we're inside a
        // quoted field, and the next character is also a quotation mark,
        // add a quotation mark to the current column and skip the next character
        if (cc == '"' && quote && nc == '"') {
            arr[row][col] += cc;
            ++c;
            continue;
        }

        // If it's just one quotation mark, begin/end quoted field
        if (cc == '"') {
            quote = !quote;
            continue;
        }

        // If it's a comma and we're not in a quoted field, move on to the next column
        if (cc == "," && !quote) {
            ++col;
            continue;
        }

        // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
        // and move on to the next row and move to column 0 of that new row
        if (cc == "\r" && nc == "\n" && !quote) {
            ++row;
            col = 0;
            ++c;
            continue;
        }

        // If it's a newline (LF or CR) and we're not in a quoted field,
        // move on to the next row and move to column 0 of that new row
        if (cc == "\n" && !quote) {
            ++row;
            col = 0;
            continue;
        }
        if (cc == "\r" && !quote) {
            ++row;
            col = 0;
            continue;
        }

        // Otherwise, append the current character to the current column
        arr[row][col] += cc;
    }
    return arr;
}

type GraphError = {
    message: string;
    code: string;
};
function tryGetGraphError(error: any): GraphError | undefined {
    if (error?.data?.error?.message) {
        return error.data.error as GraphError;
    }
}

export function handleFetchBaseQueryError(err: any) {
    if ("status" in err && "data" in err) {
        return { data: err.data as any, status: err.status };
    } else {
        return undefined;
    }
}

export function confirmationContent(onClick: () => void) {
    return (
        <>
            <Stack style={{ marginRight: "10px", marginTop: 15 }} tokens={{ childrenGap: 10 }} horizontal>
                <Stack.Item grow={1}>
                    <p style={{ margin: 15 }}>
                        Your access request has been successfully submitted, you will be notified once it's processed.
                    </p>
                </Stack.Item>
            </Stack>
            <Stack
                style={{ marginRight: 25, marginTop: 15, marginBottom: 15, float: "right" }}
                tokens={{ childrenGap: 10 }}
                horizontal
            >
                <Stack.Item grow={1} style={{ clear: "both" }}>
                    <PrimaryButton text="Done" type="button" onClick={onClick}></PrimaryButton>
                </Stack.Item>
            </Stack>
        </>
    );
}

export function confirmationRoleGroupExisting(existing: string, onClick: () => void, onDismiss: () => void) {
    return (
        <>
            <Stack style={{ marginRight: "10px", marginTop: 15 }} horizontalAlign="center" tokens={{ childrenGap: 10 }}>
                <Text style={{ padding: "15px", maxWidth: "700px" }}>
                    You currently have access for your individual account for this dataset in SIP. For Role Group policy
                    submission, your individual access will be removed and then re-added along with the role group
                    security group. You will be notified once this process is completed. Ignore any removal emails in
                    the interim.
                </Text>
            </Stack>
            <Stack
                style={{ marginRight: 25, marginTop: 15, marginBottom: 15, float: "right" }}
                tokens={{ childrenGap: 10 }}
                horizontal
            >
                <Stack.Item grow={1} style={{ clear: "both" }}>
                    <PrimaryButton text="Ok" type="button" onClick={onClick}></PrimaryButton>
                </Stack.Item>
                <Stack.Item grow={1} style={{ clear: "both" }}>
                    <DefaultButton text="Cancel" type="button" onClick={onDismiss}></DefaultButton>
                </Stack.Item>
            </Stack>
        </>
    );
}

export { titleCase, parseCSV, tryGetGraphError };
