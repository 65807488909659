import { Stack, FontIcon } from "@fluentui/react";
import { FormTextField } from "../../../common/components/FormTextField";
import { DataSetDetail } from "../DataSetDetail.types";
import { TooltipHost, ITooltipHostStyles } from "@fluentui/react/lib/Tooltip";
import { useId } from "@fluentui/react-hooks";
import { DataSetOwnersDisplay } from "../../../common/components/DataSetOwnersDisplay";

function Overview({ dataset }: { dataset: DataSetDetail }) {
    const availableIn: string[] = [];

    if (dataset.hasAdls) availableIn.push("ADLS");
    if (dataset.hasKusto) availableIn.push("Kusto");
    if (dataset.hasSDW) availableIn.push("SQL");

    const calloutProps = { gapSpace: 0 };
    const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };
    const tooltipId = useId("tooltip");

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <FormTextField label="Description" txt={dataset.description} />
                {dataset.isDescriptionManuallyOverwritten === false && (
                    <TooltipHost
                        content="This content was generated by AI"
                        title="This content was generated by AI"
                        id={tooltipId}
                        calloutProps={calloutProps}
                        styles={hostStyles}
                    >
                        <FontIcon
                            role="img"
                            aria-label="Info"
                            iconName="Info"
                            style={{ fontSize: "16px", color: "#0078D4", paddingLeft: "10px" }}
                            aria-describedby={tooltipId}
                        />
                    </TooltipHost>
                )}
            </div>

            <FormTextField label="Platform" txt={dataset.platformName} />
            <FormTextField
                label="Category"
                txt={dataset.platformName == "Phoenix" ? "Cyber Security" : "Physical Security"}
            />
            <FormTextField label="Access Security Group" txt={dataset.securityGroupName} />
            <FormTextField label="Communication Alias" txt={dataset.m365NotificationGroupName} />
            <FormTextField label="Available In" txt={availableIn.join(", ")} />
            {!dataset.isPlaceholder && <DataSetOwnersDisplay accessPackageId={dataset.accessPackageId} />}
        </Stack>
    );
}

export { Overview };
