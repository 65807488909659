import { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StandardForm from "../../common/components/StandardForm";
import {
    Checkbox,
    ComboBox,
    DefaultButton,
    FontWeights,
    IComboBoxOption,
    Label,
    Link,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Separator,
    Stack,
    Text,
    TextField,
    TeachingBubble,
    IButtonProps
} from "@fluentui/react";
import BackButton from "../../common/components/BackButton";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { AssignmentRequest } from "./DatasetCollection.type";
import { useGetDatasetCollectionQuery, useRequestRoleGroupAccessMutation } from "./slice";
import { confirmationContent, confirmationRoleGroupExisting } from "../../common/utils";
import { SipMonModal } from "../SipMonModal";
import { useAppSelector } from "../../common/state";
import { selectError } from "../../common/state/errorReducer";
import { LoadingOverLay } from "@sipmon/reactcomponents";

const localSessingKey = "existingAssignmentMessageSeen";
const RoleGroupOboRequestForm = () => {
    const { collectionAccessPkgId } = useParams<{
        collectionAccessPkgId: string;
    }>() as {
        collectionAccessPkgId: string;
    };
    const { specificError } = useAppSelector(selectError);
    const { data: collection, isLoading: collectionLoading } = useGetDatasetCollectionQuery(collectionAccessPkgId);
    const [assignmentRequest, { isLoading, isSuccess }] = useRequestRoleGroupAccessMutation();
    const [isAgreed, setIsAgreed] = useState(false);
    const [justification, setJustification] = useState("");
    const [displayForm, setDisplayForm] = useState(false);
    const [showExistingAssingmentWarning, setShowExistingAssingmentWarning] = useState(false);
    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);
    const existingAssignmentId = useId("existingAssignmentId");

    const existingAssignmentButtonProps: IButtonProps = useMemo(
        () => ({
            children: "Got it",
            onClick: () => {
                localStorage.setItem(localSessingKey, "true");
                toggleTeachingBubbleVisible();
            }
        }),
        [toggleTeachingBubbleVisible]
    );
    async function submitRequest() {
        try {
            const request: AssignmentRequest = {
                targetAccessPkgRefId: collectionAccessPkgId,
                justification: justification,
                roleGroupAccessPkgRefId: collection?.roleGroup.accessPackageId || ""
            };
            await assignmentRequest(request);
        } catch (error) {}
    }

    async function checkExistingAssignment() {
        if (collection?.otherPolicy) {
            setShowExistingAssingmentWarning(true);
        } else {
            await submitRequest();
        }
    }
    useEffect(() => {
        if (collection && collection.roleGroup && collection.roleGroup.accessStatus !== "Unknown") {
            setDisplayForm(false);
            navigate(`/collections/${collectionAccessPkgId}`);
        } else if (collection && collection.roleGroup) {
            setDisplayForm(true);
        }
        if (collection?.existingRoleGroups && collection.existingRoleGroups.length > 0) {
            const seen = localStorage.getItem(localSessingKey);
            if (!seen) toggleTeachingBubbleVisible();
        }
    }, [collection]);

    const navigate = useNavigate();

    const subTextStyle = {
        root: {
            size: 12,
            color: "gray",
            fontWeight: FontWeights.regular,
            padding: 0
        }
    };
    return (
        <>
            {displayForm && collection && (
                <>
                    <StandardForm widthPercent={50} mediumWidthPercent={80} smallWidthPercent={90}>
                        {specificError["requestRoleGroupAccess"] && (
                            <MessageBar messageBarType={MessageBarType.severeWarning}>
                                {specificError["requestRoleGroupAccess"].message}
                            </MessageBar>
                        )}
                        {(isSuccess || isLoading) && (
                            <SipMonModal
                                headerText={"SIP - Access Request"}
                                content={confirmationContent(() => navigate("/"))}
                                isLoading={isLoading}
                                loadingLabel={"Please wait, saving..."}
                                onDismiss={() => {
                                    navigate("/");
                                }}
                            />
                        )}

                        {teachingBubbleVisible && (
                            <TeachingBubble
                                target={`#${existingAssignmentId}`}
                                primaryButtonProps={existingAssignmentButtonProps}
                                onDismiss={toggleTeachingBubbleVisible}
                                headline="There are already existing role group assignments"
                            >
                                Another user has already requested access to this role group. You shouldn't need to
                                request unless you have a specific reason to do so.
                            </TeachingBubble>
                        )}

                        {showExistingAssingmentWarning && (
                            <SipMonModal
                                headerText={"SIP - Access Request"}
                                content={confirmationRoleGroupExisting(
                                    collection.otherPolicy!,
                                    async () => {
                                        setShowExistingAssingmentWarning(false);
                                        await submitRequest();
                                    },
                                    () => setShowExistingAssingmentWarning(false)
                                )}
                                isLoading={isLoading}
                                onDismiss={async () => {
                                    setShowExistingAssingmentWarning(false);
                                }}
                            />
                        )}

                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <Stack horizontal horizontalAlign="end">
                                    <Stack.Item align="end" style={{ textAlign: "right" }}>
                                        <BackButton />
                                    </Stack.Item>
                                </Stack>
                                <Text variant="large">Role Group Request</Text>
                                <Separator />
                                <Stack horizontal horizontalAlign="start">
                                    <Text style={{ lineHeight: 2.4 }} variant="medium">
                                        You are requesting on behalf of the members of{" "}
                                        <b>{collection?.roleGroup.securityGroupName}</b> to access the role group
                                    </Text>
                                </Stack>

                                <TextField
                                    multiline={true}
                                    value={justification}
                                    onChange={(_e, value) => setJustification(value || "")}
                                    label="Buiness Justification"
                                />

                                <Label styles={subTextStyle} style={{ clear: "both", paddingTop: 5 }}>
                                    You must view and agree the terms of the Confidentiality Agreement linked in the
                                    description. By selecting yes, stating that you have read and agree to the terms of
                                    the Confidentiality Agreement.
                                    <Link href="https://polite-bush-0adbeba1e.2.azurestaticapps.net/" target="_blank">
                                        {" "}
                                        SIP Confidentiality Agreement
                                    </Link>
                                </Label>
                                <Stack horizontal>
                                    <Checkbox
                                        id={"chkAgreement"}
                                        styles={{ root: { float: "left" } }}
                                        checked={isAgreed}
                                        onChange={(_e, _checked) => {
                                            setIsAgreed(!isAgreed);
                                        }}
                                    />

                                    <Label style={{ padding: 0 }} required>
                                        Read Confidentiality Agreement?
                                    </Label>
                                </Stack>
                            </Stack>
                            <Separator />
                            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 10 }}>
                                <PrimaryButton
                                    text="Submit"
                                    onClick={checkExistingAssignment}
                                    disabled={!isAgreed || !justification}
                                />
                                <DefaultButton
                                    text="Cancel"
                                    onClick={() => navigate(`/collections/${collectionAccessPkgId}`)}
                                />
                            </Stack>
                        </Stack>
                    </StandardForm>
                    {collection.existingRoleGroups && collection.existingRoleGroups.length > 0 && (
                        <StandardForm widthPercent={50} mediumWidthPercent={80} smallWidthPercent={90}>
                            <Text id={existingAssignmentId} variant="large">
                                Existing Role Group Assignments
                            </Text>
                            <Separator />
                            {collection.existingRoleGroups.map((roleGroup) => (
                                <Stack tokens={{ childrenGap: 10 }} horizontalAlign="start">
                                    <Text key={roleGroup.displayName} variant="medium">
                                        {roleGroup.displayName} - Status: {roleGroup.requestState}
                                    </Text>
                                </Stack>
                            ))}
                        </StandardForm>
                    )}
                </>
            )}
            <LoadingOverLay isOverlayVisible={collectionLoading} />
        </>
    );
};

export default RoleGroupOboRequestForm;
