import { Stack, Text, Label, Separator } from "@fluentui/react";
import { FormTextField } from "../../../common/components/FormTextField";
import { DatasetContentProps } from "../DatasetCardGrid/Types";

export function KustoDatasetContent({ currentDataSet }: DatasetContentProps) {
    return (
        <Stack>
            <Separator>Connection Details</Separator>
            <FormTextField label="Cluster Url" txt={currentDataSet.kustoConnectionString?.toLowerCase()} />
            <FormTextField label="Database Name" txt={currentDataSet.kustoDatabase} />
            <FormTextField label="Type" txt={currentDataSet.kustoType} />
            <FormTextField label="Name" txt={currentDataSet.kustoTable} />
            <Separator>Additional Information</Separator>
            <FormTextField
                label="Retention Period"
                txt={`${currentDataSet.kustoRetentionInDays} ${
                    currentDataSet.kustoRetentionInDays && currentDataSet.kustoRetentionInDays > 1 ? "Days" : "Day"
                }`}
            />
            {currentDataSet.kustoSnapshotType && (
                <FormTextField label="Snapshot Type" txt={currentDataSet.kustoSnapshotType} />
            )}
        </Stack>
    );
}
