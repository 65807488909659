export type Contexts = "adls" | "kusto" | "sdw";
export type ContextValue = { key: Contexts; text: string; iconProps: { iconName: string } };

export type ContextMap = {
    ADLS: ContextValue;
    Kusto: ContextValue;
    SQL: ContextValue;
};

export const contextMap: ContextMap = {
    ADLS: { key: "adls", text: "ADLS", iconProps: { iconName: "adls" } },
    Kusto: { key: "kusto", text: "Kusto", iconProps: { iconName: "adx" } },
    SQL: { key: "sdw", text: "SQL", iconProps: { iconName: "sdw" } }
};
