import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state";
import { dataCatalogClient } from "../clients";

interface FavoriteDatasetState {
    favoriteDatasets?: number[] | undefined;
    errors?: string;
    isLoading?: boolean;

}
export const sliceName = "favorites";

const initialState: FavoriteDatasetState = {
    favoriteDatasets: undefined,
    errors: "",
    isLoading: false
};

// Get Favorite Datasets for the current user
const getAllFavoriteDatasets = createAsyncThunk(
    `${sliceName}/get_all_fav_datasets`,
    async () => {
        const response = await dataCatalogClient.get<number[]>(`datasets/getfavoritedatasets`);
        return response.data;
    }
);

export const favoriteDatasetSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        setFavoriteDatasets: (state, action: PayloadAction<number[]>) => {
            state.favoriteDatasets = action.payload;
        },
        clearFavoriteDatasets: (state) => {
            state.favoriteDatasets = [];
        },
    },
    extraReducers: (builder) => {
        //Retrive favorite datasets
        builder.addCase(getAllFavoriteDatasets.pending, (state, _action) => {
            state.isLoading = true;
            state.errors = "";
        });
        builder.addCase(getAllFavoriteDatasets.fulfilled, (state, _action) => {
            state.isLoading = false;
            state.errors = "";
            state.favoriteDatasets = _action.payload;
        });
        builder.addCase(getAllFavoriteDatasets.rejected, (state, _action) => {
            state.isLoading = false;
            state.errors = "Error occured while retrieving favorite datasets";
        });
    }
});


export const { setFavoriteDatasets, clearFavoriteDatasets } = favoriteDatasetSlice.actions;

export { getAllFavoriteDatasets }

export const selectFavoriteDatasets = (state: RootState) => state.favoriteDatasets

export default favoriteDatasetSlice.reducer;
