import {
    Stack,
    MessageBar,
    MessageBarType,
    Pivot,
    PivotItem,
    IconButton,
    TeachingBubble,
    FontIcon,
    TooltipHost,
    Text,
    DefaultButton,
    Link
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../common/state";
import { selectError } from "../../../common/state/errorReducer";
import { useGetDataSetQuery } from "../slice";
import { useId } from "@fluentui/react-hooks";
import { Overview } from "./Overview";
import { Schema } from "../SchemaViews/Schema";
import { DatasetProperties } from "./DatasetProperties";
import TreeViewSchema from "../SchemaViews/TreeViewSchema";
import { SampleData } from "../../RenderTableView";
import PageVisitsMetrics from "../../PageVisitsMetrics/PageVisitsMetrics";
import { LoadingOverLay } from "@sipmon/reactcomponents";
import debounce from "lodash.debounce";
import { usePrefetch } from "../SchemaViews/slice";
import { DataQuality } from "./DataQualityContent";
import { AdlsDataQuality } from "./AdlsDataQualityContent";
import { AdlsIngestionTrendReport } from "./AdlsIngestionTrendReport";
import ConsumptionLayerSelector from "./ConsumptionLayerSelector";
import { Contexts, contextMap } from "../../../common/models/ConsumptionContext";
import BackButton from "../../../common/components/BackButton";
import FeedbackModal from "./FeedbackModal";
import { usePrefetch as useCollectionPrefetch } from "../../DataSetCollections/slice";
export const InfoPanelContent = ({ id }: { id?: number }) => {
    const { specificError, currentError } = useAppSelector(selectError);
    const [showLinkBubble, setShowLinkBubble] = useState(false);

    const [copyLink, setCopyLink] = useState("");
    const navigate = useNavigate();
    const params = useParams<{ id: string }>();
    const _id = id ?? +(params.id || 0);
    const linkButtonId = useId("linkButton");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [context, setContext] = useState<Contexts>(contextMap.ADLS.key);
    const { data: currentDataSet, isLoading } = useGetDataSetQuery(_id, { pollingInterval: 600000 });
    const prefetchSchema = usePrefetch("getSchema");
    const prefetchCollection = useCollectionPrefetch("getDatasetCollection");

    useEffect(() => {
        if (currentDataSet) {
            prefetchCollection(currentDataSet.accessPackageId, { ifOlderThan: 1200 });
        }
    }, [currentDataSet]);
    function getSchemaView() {
        if (context && currentDataSet) {
            if (context === "adls") {
                return <TreeViewSchema dataset={currentDataSet} />;
            } else {
                return <Schema dataset={currentDataSet} type={context} />;
            }
        } else {
            return <></>;
        }
    }
    function getDataQualityView() {
        if (context && currentDataSet) {
            if (context === "adls") {
                return <AdlsDataQuality dataset={currentDataSet} />;
            } else {
                return <DataQuality dataset={currentDataSet} type={context} />;
            }
        } else {
            return <></>;
        }
    }

    const handleSchemaPrefetch = () => {
        if (currentDataSet && (context === "sdw" || context === "kusto")) {
            let apiURL = `api/datasets/${context}/schema/${
                context === "sdw" ? currentDataSet?.sdwId : currentDataSet?.kustoId
            }`;
            prefetchSchema(apiURL, { ifOlderThan: 500 });
        }
    };

    return (
        <Stack>
            <Stack horizontal horizontalAlign="space-between">
                {/*component to show page visit counts*/}
                <PageVisitsMetrics />
            </Stack>
            <FeedbackModal show={showModal} onClose={() => setShowModal(false)} />
            {(!specificError["getDataSet"] && currentDataSet && !isLoading && (
                <Stack>
                    {currentError && (
                        <Stack.Item style={{ zIndex: "99" }}>
                            <MessageBar messageBarType={MessageBarType.error}>{currentError.message}</MessageBar>
                        </Stack.Item>
                    )}
                    {showLinkBubble && (
                        <TeachingBubble
                            target={`#${linkButtonId}`}
                            hasSmallHeadline={true}
                            onDismiss={setShowLinkBubble}
                            headline="Link Copied To Clipboard"
                            closeButtonAriaLabel="Close"
                        >
                            {copyLink}
                        </TeachingBubble>
                    )}

                    <Stack tokens={{ childrenGap: 10 }} horizontal horizontalAlign="space-between">
                        <Stack style={{ marginTop: "-30px" }} tokens={{ childrenGap: 10 }} horizontal>
                            <Stack.Item>
                                <div
                                    role="heading"
                                    style={{ lineHeight: "27px", fontSize: "20px", fontWeight: 600 }}
                                    aria-level={1}
                                >
                                    {currentDataSet.name}{" "}
                                    <TooltipHost
                                        content={
                                            <>
                                                <Text block>
                                                    {currentDataSet.my
                                                        ? "You have access to this dataset"
                                                        : "You don't have access to this datasets"}
                                                </Text>
                                            </>
                                        }
                                        id={"accessToolTip"}
                                        calloutProps={{ gapSpace: 0 }}
                                    >
                                        <FontIcon
                                            aria-describedby="accessToolTip"
                                            iconName={currentDataSet.my ? "UnlockSolid" : "LockSolid"}
                                            style={{ color: currentDataSet.my ? "green" : "red" }}
                                        />
                                    </TooltipHost>
                                </div>
                            </Stack.Item>
                            <DefaultButton
                                disabled={currentDataSet.isPlaceholder}
                                text="Request Access"
                                onClick={() => navigate(`/collections/${currentDataSet.accessPackageId}`)}
                            />
                        </Stack>

                        <Stack>
                            <Stack.Item>
                                <BackButton />
                            </Stack.Item>
                            <Stack.Item>
                                <IconButton
                                    title="Copy link to clipboard"
                                    aria-label="Copy link to clipboard"
                                    id={linkButtonId}
                                    onClick={() => {
                                        const shareLink = window.location.toString();
                                        setCopyLink(shareLink);
                                        setShowLinkBubble(true);

                                        navigator.clipboard.writeText(shareLink);
                                        setTimeout(() => {
                                            setShowLinkBubble(false);
                                            setCopyLink("");
                                        }, 1500);
                                    }}
                                    styles={{ icon: { fontSize: 22 } }}
                                    iconProps={{ iconName: "copy" }}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <IconButton
                                    title="Feedback"
                                    onClick={() => {
                                        setShowModal(true);
                                    }}
                                    iconProps={{ iconName: "feedback" }}
                                    styles={{ icon: { fontSize: 22 } }}
                                />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                    <Stack.Item style={{ marginTop: "-90px" }} align="start">
                        <ConsumptionLayerSelector
                            currentDataSet={currentDataSet}
                            context={context}
                            setContext={setContext}
                        />
                    </Stack.Item>
                    <Pivot
                        styles={{
                            itemContainer: {
                                borderTop: "solid",
                                borderColor: "gray",
                                paddingTop: "10px",
                                marginTop: "10px"
                            }
                        }}
                        aria-label="Dataset Details"
                        linkSize="large"
                    >
                        <PivotItem headerText="Overview">
                            <Overview dataset={currentDataSet} />
                        </PivotItem>
                        <PivotItem headerText="Properties">
                            <DatasetProperties currentDataSet={currentDataSet} type={context} />
                        </PivotItem>
                        <PivotItem
                            headerText="Schema"
                            headerButtonProps={{
                                onMouseEnter: debounce(() => {
                                    handleSchemaPrefetch();
                                }, 1000)
                            }}
                        >
                            {getSchemaView()}
                        </PivotItem>
                        <PivotItem headerText="Sample Data">
                            <SampleData dataset={currentDataSet} type={context} />
                        </PivotItem>
                        <PivotItem headerText="Data Quality">
                            <PivotItem headerText="DataQuality">{getDataQualityView()}</PivotItem>
                        </PivotItem>
                        {context === "adls" && (
                            <PivotItem headerText="Ingestion Trend">
                                <AdlsIngestionTrendReport
                                    filterId={currentDataSet?.adlsId ? currentDataSet?.adlsId : 0}
                                />
                            </PivotItem>
                        )}
                    </Pivot>
                </Stack>
            )) ||
                (specificError["getDataSet"] && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        {specificError["getDataSet"].message}
                        {specificError["getDataSet"].link && (
                            <Link href={specificError["getDataSet"].link}>Click here to request access</Link>
                        )}
                    </MessageBar>
                ))}

            <LoadingOverLay isOverlayVisible={isLoading && !currentDataSet} />
        </Stack>
    );
};
