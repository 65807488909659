import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authenticationParameters, authProvider } from "../../common/auth";
import { AssignmentRequest, AssignmentResponse, DatasetCollection } from "../DataSetCollections/DatasetCollection.type";

export const collectionsApi = createApi({
    reducerPath: "collections",
    tagTypes: ["collections"],
    baseQuery: fetchBaseQuery({
        baseUrl: "api/collections/",
        prepareHeaders: async (headers, {}) => {
            await authProvider.acquireTokenSilent(authenticationParameters).then((token) => {
                if (token) {
                    if (token.accessToken) {
                        headers.set("authorization", `Bearer ${token.accessToken}`);
                    }
                }
            });
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getDatasetCollection: builder.query<DatasetCollection, string>({
            query: (accessPackageId: string) => `${accessPackageId}`,
            providesTags: () => [{ type: "collections" as const, id: "LIST" }],
            keepUnusedDataFor: 60
        }),
        getMultiDatasets: builder.query<DatasetCollection[], number>({
            query: (adlsDatasetId: number) => `multidataset/${adlsDatasetId}`,
            keepUnusedDataFor: 60
        }),
        requestRoleGroupAccess: builder.mutation<AssignmentResponse, AssignmentRequest>({
            query: (request: AssignmentRequest) => ({
                url: "addRoleGroupObo",
                method: "POST",
                body: request
            }),
            invalidatesTags: [{ type: "collections" as const, id: "LIST" }]
        })
    })
});

export const {
    useGetDatasetCollectionQuery,
    usePrefetch,
    useGetMultiDatasetsQuery,
    useRequestRoleGroupAccessMutation
} = collectionsApi;
