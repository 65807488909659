import * as React from "react";
import {
    Text,
    PrimaryButton,
    MessageBar,
    MessageBarType,
    List,
    Separator,
    FocusZone,
    FocusZoneDirection,
    getTheme,
    ITheme,
    getFocusStyle,
    mergeStyleSets,
    ScrollablePane
} from "@fluentui/react";
import { useState, useEffect } from "react";
import { LoadingOverLay } from "@sipmon/reactcomponents";
import Wrapper from "./Wrapper";
import UserAccessRequestForm from "./UserAccessRequestForm";
import { checkAccess, getPlatformDetails, selectUserAccessRequest } from "./slice";
import { useAppDispatch, useAppSelector } from "../../common/state";
import { SipMonModal } from "../SipMonModal";
import { confirmationContent } from "../../common/utils";
import { useMsal } from "@azure/msal-react";
import { RolePlatform } from "./AccessRequest.types";

const AuthenticatedAccessRequestPage: React.FC = () => {
    const { platformDetails, isRequestLoading, isRequestSuccess, requestError } =
        useAppSelector(selectUserAccessRequest);
    const dispatch = useAppDispatch();
    const { instance } = useMsal();

    const [existing, setExisting] = useState<{ message: string }>();

    const [existingPlatforms, setExistingPlatforms] = useState<string[]>();

    const [disabled, setDisabled] = useState<boolean>(false);

    const [filteredPlatforms, setFilteredPlatforms] = useState<RolePlatform[]>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    useEffect(() => {
        if (isRequestSuccess || isRequestLoading) {
            setShowConfirmationModal(true);
        }
    }, [isRequestSuccess, isRequestLoading]);

    useEffect(() => {
        if (isRequestSuccess && !showConfirmationModal) {
            setTimeout(() => {
                dispatch(checkAccess());
            }, 2000);
        }
    }, [showConfirmationModal, isRequestSuccess]);

    useEffect(() => {
        dispatch(getPlatformDetails());
    }, []);

    useEffect(() => {
        if (platformDetails) {
            const filtered = filterOutExistingRoles(platformDetails);
            const existingRoles = getExistingRoles(platformDetails);

            if (filtered.length === 0) {
                setDisabled(true);
            }

            if (existingRoles.length > 0) {
                setExisting({
                    message: `You have an existing assignment for the following:`
                });
                setExistingPlatforms(existingRoles.map((role) => role.displayName));
            }
            setFilteredPlatforms(filtered);
        }
    }, [platformDetails]);

    function filterOutExistingRoles(platformDetails: RolePlatform[]) {
        const account = instance.getActiveAccount();
        return platformDetails?.filter((platform) => !account?.idTokenClaims?.roles?.includes(platform.role));
    }

    function getExistingRoles(platformDetails: RolePlatform[]) {
        const account = instance.getActiveAccount();
        return platformDetails?.filter((platform) => account?.idTokenClaims?.roles?.includes(platform.role));
    }

    const theme: ITheme = getTheme();
    const { palette, semanticColors } = theme;
    const classNames = mergeStyleSets({
        itemCell: [
            getFocusStyle(theme, { inset: -1 }),
            {
                minHeight: 30,
                justifyContent: "center", // Center horizontally
                alignItems: "center",
                boxSizing: "border-box",
                borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                display: "flex",
                selectors: {
                    "&:hover": { background: palette.neutralLight }
                }
            }
        ]
    });

    return (
        <div style={{ height: "100vh" }}>
            <Wrapper>
                {requestError && <MessageBar messageBarType={MessageBarType.severeWarning}>{requestError}</MessageBar>}
                <LoadingOverLay isOverlayVisible={showModal} />
                <Text variant="xxLarge">Welcome to the SIP Data Catalog!</Text>
                {showModal && filteredPlatforms && (
                    <UserAccessRequestForm
                        platformDetails={filteredPlatforms}
                        closeModal={() => {
                            setShowModal(false);
                        }}
                    />
                )}

                {showConfirmationModal && !requestError && (
                    <SipMonModal
                        headerText={"SIP - Access Request"}
                        content={confirmationContent(() => setShowConfirmationModal(false))}
                        isLoading={isRequestLoading}
                        loadingLabel={"Please wait, submitting..."}
                        onDismiss={() => setShowConfirmationModal(false)}
                    />
                )}
                {existing && <Text variant="large">{existing.message}</Text>}
                {existingPlatforms && (
                    <List
                        style={{ maxHeight: "25%", width: "40%", overflow: "auto" }}
                        items={existingPlatforms}
                        onRenderCell={(item, index) => (
                            <div className={classNames.itemCell}>
                                <Text>{item}</Text>
                            </div>
                        )}
                    />
                )}
                {existing && (
                    <Text variant="large">Please click on the link below to request access to another platform.</Text>
                )}
                <PrimaryButton disabled={disabled} text="Request Access" onClick={() => setShowModal(true)} />

                {disabled && <Text variant="medium">You already have access to all platforms.</Text>}
            </Wrapper>
        </div>
    );
};

export default AuthenticatedAccessRequestPage;
