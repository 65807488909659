import { SelectionMode } from "@fluentui/react";
import { useEffect, useState } from "react";
import { ShimmeredList } from "../../../common/components/ShimmerList";
import { useAppDispatch } from "../../../common/state";
import { setError } from "../../../common/state/errorReducer";
import { ISchema } from "../../RenderTableView";
import { DataSetDetailProps } from "../DataSetDetail.types";
import { useGetSchemaQuery } from "./slice";
import { AdlsSchema, AdlsSchemaField, downloadContent, getColumns, getTreeItem, getTreeViewStyles } from "./utils";
import { DownloadSchemaButton } from "./DownloadSchemaButton";

function TreeViewSchema({ dataset }: DataSetDetailProps) {
    const [schema, setSchema] = useState<AdlsSchema>();
    const [items, setItems] = useState<{ name: string; value: JSX.Element }[]>();
    const [error, _setError] = useState<boolean>();
    const dispatch = useAppDispatch();

    // Get adls schema from Database
    let apiURL = `api/datasets/adls/schema/${dataset.adlsId}`;
    const { data, isLoading, error: schemaFetchError } = useGetSchemaQuery(apiURL, { pollingInterval: 600000 });
    let schemaFinal: AdlsSchema = { fields: [], type: "struct" };

    useEffect(() => {
        _setError(false);
        getSchemaInfo();
    }, [data]);

    useEffect(() => {
        if (schemaFetchError) {
            _setError(true);
            dispatch(
                setError({ message: `An error occurred getting schema for ${dataset.name}`, errorType: "Schema" })
            );
        }
    }, [schemaFetchError]);

    useEffect(() => {
        if (dataset && dataset.adlsId) {
            setSchema(undefined);
            getSchemaInfo();
        }
    }, [dataset]);

    useEffect(() => {
        if (schema && schema.fields) {
            const fields = schema.fields.map((x) => ({
                name: x.name,

                value: <fluent-tree-view class={getTreeViewStyles()}>Root {getTreeItem(x, true)}</fluent-tree-view>
            }));

            setItems(fields);
        }
    }, [schema]);
    function getSchemaInfo() {
        // building schema structure from data fetched from database
        if (!isLoading && data) {
            schemaFinal.fields = [];
            data.filter((x) => x.parentId == 0).map((item) => {
                schemaFinal.fields.push(getSchemaStructure(data, item));
            });
            setSchema(schemaFinal);
        }
    }

    function getSchemaStructure(data: ISchema[], currentChild: ISchema): AdlsSchemaField {
        //Creating current child object
        let child: AdlsSchemaField = {
            name: currentChild.name,
            type: { fields: [], type: "" }
        };

        //Looking for childrens in all schema data
        var currentChildren = data.filter((item) => item.parentId === currentChild.id);

        if (currentChildren.length > 0) {
            currentChildren.forEach(function (item) {
                const parent = data.find((x) => x.id === item.parentId);
                //Iterating threw children and calling the recursive function
                //Adding the result to our current children
                child.type["fields"].push(getSchemaStructure(data, item));
                child.type["type"] = parent?.type;
            });
        } else {
            // If no childrens found, we are adding the type to our current child
            child.type = currentChild.type;
        }

        return child;
    }

    function createCSV(data: ISchema[]): string {
        const map = new Map(data.map((item) => [item.id, item]));

        function getFullName(id: number): string {
            const item = map.get(id);
            if (item && item.parentId && item.parentId !== 0) {
                return `${getFullName(item.parentId)}.${item.name}`;
            } else {
                return item?.name || "";
            }
        }

        let csv = "Name,Type\n";
        data.forEach((item) => {
            if (item) {
                const fullName = getFullName(item.id);
                csv += `${fullName},${item.type}\n`;
            }
        });

        return csv;
    }

    // ADLS Schema - Download
    function downloadSchema(filename: string, format: "json" | "csv" = "json") {
        try {
            _setError(false);

            try {
            } catch (error) {
                console.log(error);
            }
            if (format === "json") {
                downloadContent(JSON.stringify(schema), filename, ".json");
            } else if (format === "csv") {
                if (data) {
                    const csv = createCSV(data);
                    downloadContent(csv, filename, ".csv");
                }
            }
        } catch (e) {
            _setError(true);
            dispatch(
                setError({ message: `An error occurred downloading schema for ${dataset.name}`, errorType: "Schema" })
            );
        }
    }

    return (
        <>
            {schema && <DownloadSchemaButton multipleOptions onClick={(e) => downloadSchema(dataset.name, e)} />}
            <ShimmeredList
                height="65vh"
                columns={getColumns({ cellTitle: { paddingLeft: "24px" } })}
                selectionMode={SelectionMode.none}
                items={items || []}
                enableShimmer={!items && !error}
            />
        </>
    );
}

export default TreeViewSchema;
