import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authenticationParameters, authProvider } from "../auth";
import { RootState } from "./rootReducer";

export const pageViewsAPI = createApi({
    reducerPath: "pageViewsAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
        prepareHeaders: async (headers, { }) => {
            await authProvider.acquireTokenSilent(authenticationParameters).then((token) => {
                if (token) {
                    // If we have a token set in state, let's assume that we should be passing it.
                    if (token.accessToken) {
                        headers.set("authorization", `Bearer ${token.accessToken}`);
                    }
                }
            });
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getPageViewsDetails: builder.query({
            query: () => `api/datacatalogfeature/getpageviewsinfo`,
            keepUnusedDataFor: 60 // invalidate after 60 seconds
        })
    })
});

export const { useGetPageViewsDetailsQuery } = pageViewsAPI;
export const pageViews = (state: RootState) => state.pageViewsAPI;


