import { useParams } from "react-router-dom";
import { useGetDatasetCollectionQuery, useGetMultiDatasetsQuery } from "../slice";
import { MessageBar, MessageBarType, Separator, Stack } from "@fluentui/react";
import { DatasetCardGrid } from "../../DataSets/DatasetCardGrid";
import { useEffect, useState } from "react";
import BackButton from "../../../common/components/BackButton";
import { LoadingOverLay } from "@sipmon/reactcomponents";
import AccessRequestHeader from "../AccessRequestHeader";
import { DatasetCollection } from "../DatasetCollection.type";

export const DatasetCollectionGrid = () => {
    const { accessPackageId } = useParams<{ accessPackageId: string }>() as { accessPackageId: string };
    const { data, isLoading, error: collectionError } = useGetDatasetCollectionQuery(accessPackageId);

    const [datasetId, setDatasetId] = useState<number>(0);
    const [error, setError] = useState<string>();
    const {
        data: multiDatasets,
        isLoading: multiDatasetsLoading,
        error: multiDatasetsError
    } = useGetMultiDatasetsQuery(datasetId, {
        skip: datasetId === 0
    });
    const [selectedCollection, setSelectedCollection] = useState<DatasetCollection>();

    const [selectedAccessPackageId, setSelectedAccessPackageId] = useState<string>(accessPackageId);
    const [collectionLookup, setCollectionLookup] = useState<Map<string, DatasetCollection>>();

    useEffect(() => {
        if (collectionError) {
            if (collectionError && "data" in collectionError && collectionError.data) {
                // check if data is string
                if (typeof collectionError.data === "string") {
                    setError(collectionError.data);
                } else {
                    setError(
                        (collectionError.data as any)?.title ||
                            (collectionError.data as any)?.message ||
                            (collectionError.data as any)?.error?.message
                    );
                }
            }
        }
        if (multiDatasets) {
            if (multiDatasets && "data" in multiDatasets && multiDatasets.data) {
                // check if data is string
                if (typeof multiDatasets.data === "string") {
                    setError(multiDatasets.data);
                } else {
                    setError(
                        (multiDatasets.data as any)?.title ||
                            (multiDatasets.data as any)?.message ||
                            (multiDatasets.data as any)?.error?.message
                    );
                }
            }
        }
    }, [collectionError, multiDatasets]);

    useEffect(() => {
        if (multiDatasets) {
            for (const multiDataset of multiDatasets) {
                setCollectionLookup((prev) => {
                    const newMap = new Map(prev);
                    newMap.set(multiDataset.accessPackage.id, multiDataset);
                    return newMap;
                });
            }
        }
    }, [multiDatasets]);

    useEffect(() => {
        if (selectedAccessPackageId && collectionLookup && collectionLookup.has(selectedAccessPackageId)) {
            setSelectedCollection(collectionLookup.get(selectedAccessPackageId));
        }
    }, [selectedAccessPackageId, collectionLookup]);

    useEffect(() => {
        if (data && data.datasets && data.datasets.length > 0) {
            const ds = data.datasets.find((d) => d.hasAdls);

            if (ds) {
                setDatasetId(ds.id);
            }

            setCollectionLookup((prev) => {
                const newMap = new Map(prev);
                newMap.set(data.accessPackage.id, data);
                return newMap;
            });
        }
    }, [data]);

    return (
        <>
            {error && <MessageBar messageBarType={MessageBarType.severeWarning}>{error}</MessageBar>}
            <LoadingOverLay isOverlayVisible={multiDatasetsLoading || isLoading} />
            {selectedCollection && collectionLookup && (
                <Stack disableShrink style={{ minWidth: "500px" }}>
                    <Stack styles={{ root: { padding: "10px" } }} horizontalAlign="end">
                        <BackButton />
                    </Stack>
                    <Stack horizontalAlign="center">
                        <AccessRequestHeader
                            setCollection={setSelectedAccessPackageId}
                            accessPackages={Array.from(collectionLookup.values()).map((x) => x.accessPackage)}
                            collection={selectedCollection}
                        />
                        <DatasetCardGrid
                            dataSets={collectionLookup.get(selectedAccessPackageId)?.datasets}
                            loading={isLoading}
                            isCollectionView={true}
                        />
                        <Separator />
                    </Stack>
                </Stack>
            )}
        </>
    );
};
