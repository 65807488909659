import { Icon, IconButton, PrimaryButton, TextField } from "@fluentui/react";
import { useState } from "react";
import { SipMonModal } from "../SipMonModal";
import { useAppDispatch, useAppSelector } from "../../common/state";
import { postFeeback, resetChatHistory, selectSippyChatStates } from "../../common/state/sippyChatReducer";
import { ChatBotfeedback } from "./SippyChatTypes";

export function ResponseFooter({ rFooterId, qID, chatContent, sessionId, userAlias, actionSelected }) {

    const dispatch = useAppDispatch();
    const [likeSelected, setLikeSelected] = useState<boolean>(actionSelected === "like" ? true : false);
    const [dislikeSelected, setDisLikeSelected] = useState<boolean>(actionSelected === "dislike" ? true : false);
    const [showFeedback, setShowFeedback] = useState<boolean>(false);
    const [message, setMessage] = useState("");

    const { chat: chatHistory } = useAppSelector(selectSippyChatStates);


    function onActionClick(action: string) {
        setMessage("");
        const isSelected = action === "like" ? likeSelected : dislikeSelected;
        if (!isSelected) {
            setShowFeedback(true);
        }
        setLikeSelected(action === "like" ? !likeSelected : false);
        setDisLikeSelected(action === "dislike" ? !dislikeSelected : false);

        // set selection action in store so that like / dislike can be retained in history
        const modifiedChatHistory = chatHistory.map(obj => {
            if (obj.qID === qID && obj.role === "system") {
                return { ...obj, actionSelected: !isSelected ? action : "" };
            }
            return obj;
        });
        dispatch(resetChatHistory(modifiedChatHistory));
    }
    function submitFeedback() {
        const objFeedback: ChatBotfeedback = {
            action: likeSelected ? "like" : dislikeSelected ? "dislike" : "",
            message: message,
            questionId: qID,
            sessionId: sessionId,
            userAlias: userAlias
        }
        // posting feedback to db
        const data = dispatch(postFeeback(objFeedback))
        setShowFeedback(false);
    }

    return (
        <>
            <div className="ResponseFooter">
                <div className="leftContent">
                    <IconButton id={`like+${rFooterId}`} iconProps={{ iconName: likeSelected ? "LikeSolid" : "Like" }} onClick={() => onActionClick("like")} />
                    <IconButton id={`dislike+${rFooterId}`} iconProps={{ iconName: dislikeSelected ? "DisLikeSolid" : "Dislike" }} onClick={() => onActionClick("dislike")} />
                    <div className="divider"></div>
                    <IconButton key={"copy_" + rFooterId} title="copy" iconProps={{ iconName: "copy" }} style={{ cursor: "pointer", fontSize: 18, marginLeft: 2 }} onClick={() => { navigator.clipboard.writeText(chatContent) }} />
                </div>
                <div className="rightContent">
                    <span><Icon iconName="shieldalert"></Icon></span> &nbsp;<span>AI-generated content may be incorrect</span>
                </div>
                {showFeedback && (
                    <SipMonModal
                        headerText={"Send detailed feedback to Us"}
                        content={getFeedbaclModelContent()}
                        isLoading={false}
                        onDismiss={() => setShowFeedback(false)}
                    />
                )}
            </div>
        </>
    )

    function getFeedbaclModelContent() {
        const txtBoxLable = likeSelected ? "What did you like?" : "What went wrong?";
        return <>
            <div className="feedbackContent">
                <div className="feedbackHeader">{txtBoxLable}</div>
                <div ><TextField multiline rows={5} placeholder={"Please do not include any personal information"} onChange={(_ev, v) => setMessage(v || "")}></TextField></div>
            </div>
            <div style={{ float: "right", margin: '10px 25px 14px 10px' }}><PrimaryButton text="Submit" disabled={message.length === 0} onClick={() => { submitFeedback() }}></PrimaryButton></div>
        </>

    }
}
