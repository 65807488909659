import { IconButton } from "@fluentui/react";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
    const navigate = useNavigate();
    return (
        <IconButton
            title="Back"
            onClick={() => {
                if (window.history.state) {
                    navigate(-1);
                } else {
                    navigate("/");
                }
            }}
            iconProps={{ iconName: "returnkey" }}
            styles={{ icon: { fontSize: 22 } }}
        />
    );
};

export default BackButton;
