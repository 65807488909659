import { PageVisitMetrics } from "@sipmon/reactcomponents";
import { toNumber } from "lodash";
import React, { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useGetPageViewsDetailsQuery } from "../../common/state/pageviewReducer";


const PageVisitsMetrics = () => {
    const loc = useLocation();

    const { data, isLoading, isSuccess } = useGetPageViewsDetailsQuery("pageViewsAPI");
    let count = 0;

    // Method to retrive page visit details
    const getPageVisitInfo = useCallback(async () => {
        resetStateValues();
        // Api cal
        let response;
        if (isSuccess && data && data.pageViewsData) {
            
            // Getall all the data for the current page
            response = data.pageViewsData.filter((d) => d.pageURL == window.location.href.toLowerCase());
            if (response) {
                // Total visit till now
                count = response.length;
                setTotalVisit(count == null ? 0 : toNumber(count));

                // Total visit in 180 days
                let curDate = new Date(new Date());
                curDate.setDate(curDate.getDate() - 180);
                let res = response.filter((d) => new Date(d.timestamp) >= curDate);
                setTotalVisitIn180days(count == null ? 0 : toNumber(res.length));

                // Total visit in 30 days
                curDate = new Date(new Date());
                curDate.setDate(curDate.getDate() - 30);
                res = response.filter((d) => new Date(d.timestamp) >= curDate);
                setTotalVisitIn30days(count == null ? 0 : toNumber(res.length));
            }
        }
    }, [isSuccess, loc.pathname]);

    React.useEffect(() => {
        getPageVisitInfo();
    }, [loc.pathname, isSuccess]);

    function resetStateValues() {
        setTotalVisit(0);
        setTotalVisitIn180days(0);
        setTotalVisitIn30days(0);
    }
       
    const [totalVisit, setTotalVisit] = React.useState<number>(0);
    const [totalVisitIn180days, setTotalVisitIn180days] = React.useState<number>(0);
    const [totalVisitIn30days, setTotalVisitIn30days] = React.useState<number>(0);

    return (<PageVisitMetrics isLoading={isLoading} totalVisit={totalVisit} totalVisitIn180days={totalVisitIn180days} totalVisitIn30days={totalVisitIn30days} />)   
};

export default PageVisitsMetrics;
