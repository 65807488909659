import ReactDOM from "react-dom";
import { App } from "./App";
import { loadTheme, mergeStyles, registerIcons } from "@fluentui/react";
import reportWebVitals from "./reportWebVitals";
import { authProvider } from "./common/auth/MsalConfig";
import { CoherenceTheme } from "@coherence-design-system/styles";
import { ReactComponent as StorageAccountIcon } from "./assets/images/storageAccount.svg";
import { ReactComponent as SdwIcon } from "./assets/images/sdw.svg";
import { ReactComponent as AdxIcon } from "./assets/images/adx.svg";
import "./index.css";
import { fluentTreeItem, fluentTreeView, provideFluentDesignSystem } from "@fluentui/web-components";
// Inject some global styles
mergeStyles({
    selectors: {
        ":global(body,html,#root)": {
            margin: 0,
            padding: 0,
            height: "100%",
            fontFamily: "Helvetica"
        }
    }
});
loadTheme(CoherenceTheme);
registerIcons({
    icons: {
        adls: <StorageAccountIcon title="Adls" />,
        sdw: <SdwIcon title="Sql" />,
        adx: <AdxIcon title="Kusto" />
    }
});
provideFluentDesignSystem().register(fluentTreeItem(), fluentTreeView());

declare global {
    namespace JSX {
        interface IntrinsicElements {
            ["fluent-tree-view"]: any;
            ["fluent-tree-item"]: any;
        }
    }
}

ReactDOM.render(<App msalInstance={authProvider} />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
