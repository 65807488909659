import { Stack, Separator, Link } from "@fluentui/react";
import { ReactElement } from "react";
import { FormTextField } from "../../../common/components/FormTextField";
import { DatasetContentProps } from "../DatasetCardGrid/Types";
import { ActiveICM } from "../DataSetSummary.types";

function formatDate(dateValue) {
    if (dateValue != null && dateValue != "1900-01-01T00:00:00+00:00") {
        return dateValue.split("T")[0];
    }
    return "N/A";
}

export function AdlsDatasetContent({ currentDataSet }: DatasetContentProps) {
    return (
        <Stack>
            <Separator>Connection Details</Separator>

            <FormTextField label="DFS Path" txt={currentDataSet.adlsDfsPath} />
            <FormTextField label="DFS Uri" txt={currentDataSet.adlsDfsUri} />
            <FormTextField label="Blob Uri" txt={currentDataSet.adlsBlobUri} />

            <Separator>Additional Information</Separator>
            <FormTextField
                label="Retention Period"
                txt={`${currentDataSet.adlsRetentionInDays} ${
                    currentDataSet.adlsRetentionInDays && currentDataSet.adlsRetentionInDays > 1 ? "Days" : "Day"
                }`}
            />
            <FormTextField label="Update frequency in hours" txt={`${currentDataSet.adlsUpdateFrequencyInHours}`} />
            <FormTextField label="SLA in hours" txt={`${currentDataSet.adlsSLAInHours}`} />
            <FormTextField label="Active Incidents" txt={getActiveICMDetails(currentDataSet.activeICMs)} />
            {/* Hide until purging is fixed */}
            {/* <FormTextField label="First Date Available" txt={formatDate(currentDataSet.adlsFirstDateAvailable)} /> */}
            <FormTextField label="Last Refresh" txt={formatDate(currentDataSet.adlsLatestRefresh)} />
            <FormTextField label="Partition By" txt={currentDataSet.adlsPartitionSchema} />
            <FormTextField label="Data Format" txt={currentDataSet.adlsDataFormat} />
            <FormTextField
                label="Last Ingested Count"
                txt={currentDataSet.adlsTotalRecords ? currentDataSet.adlsTotalRecords.toString() : "0"}
            />
            {currentDataSet.adlsSnapshotType && (
                <FormTextField label="Snapshot Type" txt={currentDataSet.adlsSnapshotType} />
            )}
        </Stack>
    );
}

function getActiveICMDetails(activeICMs: ActiveICM[]): ReactElement {
    if (activeICMs.length != 0) {
        return (
            <>
                {activeICMs.map((item) => (
                    <>
                        <Link
                            target="_blank"
                            href={
                                "https://portal.microsofticm.com/imp/v3/incidents/details/" + item.icmNumber + "/home"
                            }
                        >
                            {item.title}
                        </Link>
                        <br />
                    </>
                ))}
            </>
        );
    }
    return <>None</>;
}
