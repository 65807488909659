import { Card } from "@coherence-design-system/controls";
import { IShimmerElement, Shimmer, ShimmerElementType, Stack } from "@fluentui/react";

function LoadingDataSetCard() {
    const shimmerWithElementFirstRow: IShimmerElement[] = [
        { type: ShimmerElementType.circle },
        { type: ShimmerElementType.gap, width: "2%" }
    ];

    return (
        <Card
            header={
                <div style={{ padding: "10px" }}>
                    <Shimmer shimmerElements={shimmerWithElementFirstRow} />
                </div>
            }
        >
            <Stack verticalAlign={"start"} tokens={{ childrenGap: 10 }}>
                <Shimmer width="75%" />
                <Shimmer />
                <Shimmer width="50%" />
            </Stack>
        </Card>
    );
}

export default LoadingDataSetCard;
