import { useRef, useEffect, useMemo } from "react";
import debounce from "lodash.debounce";

const useDebounce = (callback: () => void, delay: number) => {
    const ref = useRef<any>(null);

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    const debouncedCallback = useMemo(() => {
        const func = () => {
            ref.current?.();
        };

        return debounce(func, delay);
    }, []);

    return debouncedCallback;
};

export default useDebounce;
