import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import axios from "axios";

const getUserPhoto = async (account: AccountInfo | null, authProvider: IPublicClientApplication): Promise<string> => {
    try {
        if (account) {
            const token = await authProvider.acquireTokenSilent({
                scopes: ["https://graph.microsoft.com/User.Read"],
                account
            });

            const headers = {
                Authorization: token.accessToken,
                "Content-Type": "image/jpg"
            };

            const { data } = await axios.get<any>(`https://graph.microsoft.com/v1.0/me/photos/48x48/$value`, {
                headers,
                responseType: "blob"
            });

            const url = window.URL || window.webkitURL;
            const blobUrl = url.createObjectURL(data);
            return blobUrl;
        } else {
            return "";
        }
    } catch (er) {
        console.error(er);
        return "";
    }
};

export { getUserPhoto };
