import { useState, useEffect } from "react";

const useIsSmallScreen = (screenSize = 768) => {
    const [smallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia(`(max-width: ${screenSize}px)`);
        const handleMediaQueryChange = (event: MediaQueryListEvent) => {
            setIsSmallScreen(event.matches);
        };
        mediaQuery.addEventListener("change", handleMediaQueryChange);
        return () => {
            mediaQuery.removeEventListener("change", handleMediaQueryChange);
        };
    }, []);

    return smallScreen;
};

export { useIsSmallScreen };
