import { Stack, MessageBar, MessageBarType } from "@fluentui/react";
import { ReactElement, useState } from "react";
import { SipMonModal } from "../../features/SipMonModal";
import { useIsAuthenticated } from "@azure/msal-react";

export function DataPrivacyAlert(): ReactElement {
    const isAuthenticated = useIsAuthenticated();

    const [showPrivacyAlert, setShowPrivacyAlert] = useState<boolean>(showAlertCheck());

    function showAlertCheck() {
        if (!isAuthenticated) {
            return false;
        }
        let lastAlertShownDate = window.localStorage.getItem("AlertShownDate");
        return lastAlertShownDate && lastAlertShownDate == new Date().toDateString() ? false : true;
    }

    function dismissAlert() {
        setShowPrivacyAlert(false);
        // set the date so we don't show it again today
        window.localStorage.setItem("AlertShownDate", new Date().toDateString());
    }

    function getPrivacyAlertContent() {
        return (
            <Stack style={{ margin: 25, marginTop: 0 }}>
                <Stack.Item style={{ paddingBottom: 10 }}>
                    <MessageBar messageBarType={MessageBarType.severeWarning}>
                        <b>Warning: Regarding Sharing SIP Data Broadly</b>
                    </MessageBar>
                </Stack.Item>
                <Stack.Item style={{ paddingLeft: 2, textAlign: "justify" }}>
                    Please be aware that any data you share on this application will be visible to anyone who can see
                    your screen. This may include data that you have agreed to keep confidential per the SIP User
                    Agreement. Please do not share your screen or the underlying data on this application. Thank you for
                    using our application and respecting SIPs terms of service.
                </Stack.Item>
            </Stack>
        );
    }

    if (showPrivacyAlert) {
        return (
            <SipMonModal
                headerText={"SIP Data Catalog"}
                content={getPrivacyAlertContent()}
                isLoading={false}
                onDismiss={() => dismissAlert()}
                customWidth={550}
            />
        );
    }

    return <></>;
}
