import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dataCatalogClient } from "../../common/clients";
import { RootState } from "../../common/state";
import axios from "axios";
import { AccessPackageAssignmentView, RolePlatform } from "./AccessRequest.types";

type UserAccessRequestState = {
    error?: string;
    isRequestLoading: boolean;
    isRequestSuccess?: boolean;
    requestError?: string;
    isCheckAccessLoading: boolean;
    isCheckAccessSuccess?: boolean;
    checkAccessError?: string;
    checkAccessMessage?: string;
    platformDetails?: RolePlatform[];
    isPlatformDetailsLoading?: boolean;
    platformDetailsError?: string;
    assignments?: AccessPackageAssignmentView[];
    isAssignmentsLoading?: boolean;
    assignmentsError?: string;
};
const sliceName = "userAccessRequest";
const initialState: UserAccessRequestState = {
    isRequestLoading: false,
    isCheckAccessLoading: false
};

const checkAccess = createAsyncThunk(`${sliceName}/checkAccess`, async () => {
    const response = await dataCatalogClient.get<{ message: string }>("/accessRequest/check");
    return response.data;
});

const getPlatformDetails = createAsyncThunk(`${sliceName}/platformDetails`, async () => {
    const response = await dataCatalogClient.get<RolePlatform[]>("/accessRequest/platformDetails");
    return response.data;
});

const getExtendableAssignments = createAsyncThunk(`${sliceName}/extendableAssignments`, async () => {
    const response = await dataCatalogClient.get<AccessPackageAssignmentView[]>("/accessRequest/extendableAssignments");
    return response.data;
});

const requestAccess = createAsyncThunk(`${sliceName}/requestAccess`, async (justification: string, thunkAPI) => {
    try {
        return await dataCatalogClient.post<void>("/accessRequest/request", { justification });
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue({ error: error.response?.data });
        } else {
            return thunkAPI.rejectWithValue({ error: error });
        }
    }
});

export const userAccessRequestSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(checkAccess.pending, (state, _action) => {
            state.isCheckAccessLoading = true;
            state.checkAccessError = undefined;
        });
        builder.addCase(checkAccess.fulfilled, (state, _action) => {
            state.isCheckAccessLoading = false;
            state.checkAccessError = undefined;
            state.checkAccessMessage = _action.payload.message;
        });
        builder.addCase(checkAccess.rejected, (state, _action) => {
            state.isCheckAccessLoading = false;
            state.checkAccessError = "Error occured while checking access, please try again later.";
        });
        builder.addCase(requestAccess.pending, (state, _action) => {
            state.isRequestLoading = true;
            state.checkAccessError = undefined;
        });
        builder.addCase(requestAccess.fulfilled, (state, _action) => {
            state.isRequestLoading = false;
            state.checkAccessError = undefined;
            state.isRequestSuccess = true;
        });
        builder.addCase(requestAccess.rejected, (state, action: PayloadAction<any>) => {
            state.isRequestLoading = false;
            state.requestError =
                action.payload?.error?.message ?? "Error occured while requesting access, please try again later.";
        });
        builder.addCase(getPlatformDetails.pending, (state, _action) => {
            state.isPlatformDetailsLoading = true;
            state.platformDetailsError = undefined;
        });
        builder.addCase(getPlatformDetails.fulfilled, (state, action) => {
            state.isPlatformDetailsLoading = false;
            state.platformDetailsError = undefined;
            state.platformDetails = action.payload;
        });
        builder.addCase(getPlatformDetails.rejected, (state, action: PayloadAction<any>) => {
            state.isPlatformDetailsLoading = false;
            state.requestError =
                action.payload?.error?.message ?? "Error occured while requesting access, please try again later.";
        });
        builder.addCase(getExtendableAssignments.pending, (state, _action) => {
            state.isAssignmentsLoading = true;
            state.assignmentsError = undefined;
        });
        builder.addCase(getExtendableAssignments.fulfilled, (state, action) => {
            state.isAssignmentsLoading = false;
            state.assignmentsError = undefined;
            state.assignments = action.payload;
        });
        builder.addCase(getExtendableAssignments.rejected, (state, action: PayloadAction<any>) => {
            state.isAssignmentsLoading = false;
            state.assignmentsError =
                action.payload?.error?.message ?? "Error occured while requesting access, please try again later.";
        });
    }
});

export { checkAccess, requestAccess, getPlatformDetails, getExtendableAssignments };
export default userAccessRequestSlice.reducer;
export const selectUserAccessRequest = (state: RootState) => state.userAccessRequests;
